import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import UserService from "../api/user/UserService";
import dayjs from "dayjs";
import FileService from "../api/files/FileService";
import { useAuth } from "../context/AuthContext";

const formatDateTime = (dateString) => {
  return dayjs(dateString).format('YYYY-MM-DD HH:mm:ss');
};

const AdminPanel = () => {
  const { isLoggedIn, isLoading } = useAuth();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [storageUsage, setStorageUsage] = useState({});
  const [storageUsageError, setStorageUsageError] = useState(null);
  const MAX_TOTAL_STORAGE_MB = process.env.REACT_APP_USER_STORAGE_LIMIT;

  const fetchStorageUsageForUsers = async (userIds) => {
    try {
      const usageData = await Promise.all(
        userIds.map(async (userId) => {
          const usage = await FileService.getUserStorageUsageByUserId(userId);
          return { userId, usage };
        })
      );
      const usageMap = usageData.reduce((acc, { userId, usage }) => {
        acc[userId] = usage;
        return acc;
      }, {});
      setStorageUsage(usageMap);
    } catch (err) {
      setStorageUsageError(err.message);
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const userList = await UserService.getAllUsers();
        setUsers(userList);
        const userIds = userList.map((user) => user.id);
        fetchStorageUsageForUsers(userIds);
      } catch (error) {
        setError("Failed to load users data.");
      } finally {
        setLoading(false);
      }
    };

    if (!isLoading && isLoggedIn) {
      fetchUsers();
    }
  }, [isLoading, isLoggedIn]);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>{error}</Typography>;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 5 }}>
      <Typography variant="h5" sx={{ mb: 3 }}>Адмін панель</Typography>
      <TableContainer component={Paper} sx={{ maxWidth: '90%' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Роль</TableCell>
              <TableCell>Активний</TableCell>
              <TableCell>Створено</TableCell>
              <TableCell>Оновлено</TableCell>
              <TableCell>Останній логін</TableCell>
              <TableCell>Підписка</TableCell>
              <TableCell>Всього доступно місця</TableCell>
              <TableCell>Використано місця</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.id}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell>{user.active ? "Так" : "Ні"}</TableCell>
                <TableCell>{formatDateTime(user.created)}</TableCell>
                <TableCell>{formatDateTime(user.updated)}</TableCell>
                <TableCell>{formatDateTime(user.lastLogin)}</TableCell>
                <TableCell>{user.subscription}</TableCell>
                <TableCell>{MAX_TOTAL_STORAGE_MB} MB</TableCell>
                <TableCell>
                  {storageUsageError
                    ? storageUsageError
                    : (storageUsage[user.id] !== undefined
                      ? `${storageUsage[user.id]} MB`
                      : "Loading...")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AdminPanel;
