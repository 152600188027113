import CloseIcon from "@mui/icons-material/Close";
import LinkIcon from "@mui/icons-material/Link";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Snackbar,
  styled,
  TextField
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import dayjs from "dayjs";
import { MDBBtn, MDBInput } from "mdb-react-ui-kit";
import React, { useEffect, useRef, useState } from "react";
import FileService from "../../api/files/FileService";
import CircularProgressWithLabel from "../common/CircularProgressWithLabel";
import AttachedFiles from "../files/AttachedFiles";
import FilesWillBeAdded from "../files/FilesWillBeAdded";
import { useAuth } from "../../context/AuthContext";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import LinkedCardsButton from "./LinkedCardsButton";
import LinkedCards from "./LinkedCards";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1
});

const LoaderOverlay = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 2000
});

const InfoCardModal = ({
                         isOpen,
                         setIsOpen,
                         infoCards,
                         fileIdsMap,
                         setFileIdsMap,
                         activeInfoCard,
                         setActiveInfoCard,
                         handleInfoCardDelete,
                         handleInfoCardClick,
                         fileProgress,
                         initialFilesForActiveInfoCard,
                         setInitialFilesForActiveInfoCard,
                         loadAttachedFiles,
                         activeCardFileIds,
                         fetchImageUrls,
                         fetchInfoCards,
                         mode,
                         fetchStorageUsage,
                         storageUsage,
                         documentTypes,
                         activeUser
                       }) => {
  const { isMobile } = useAuth();
  const [newFiles, setNewFiles] = useState([]);
  const [reorderedFiles, setReorderedFiles] = useState([]);
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [documentType, setDocumentType] = useState(0);
  const [serviceName, setServiceName] = useState("");
  const [organization, setOrganization] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [doctor, setDoctor] = useState("");
  const [date, setDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [price, setPrice] = useState("");
  const [comment, setComment] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initialInfoCard, setInitialInfoCard] = useState({});
  const [progress, setProgress] = useState(0);
  const [linkCardModalOpen, setLinkCardModalOpen] = useState(false);
  const [linkedCards, setLinkedCards] = useState([]);
  const progressRef = useRef(progress);
  const allowedFileTypes = ["application/pdf", "image/jpeg", "image/png"];
  const MAX_FILE_SIZE_MB = 100;
  const MAX_TOTAL_STORAGE_MB = process.env.REACT_APP_USER_STORAGE_LIMIT;

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const waitForUpload = async () => {
    const checkInterval = 500;
    const maxRetries = 120;

    let retries = 0;
    const intervalId = setInterval(() => {
      if (progressRef.current === 100 || retries >= maxRetries) {
        clearInterval(intervalId);
      } else {
        retries++;
      }
    }, checkInterval);
  };

  const [
    openConfirmationDialogInfoCardCancel,
    setOpenConfirmationDialogInfoCardCancel
  ] = useState(false);
  const [
    openConfirmationDialogInfoCardDelete,
    setOpenConfirmationDialogInfoCardDelete
  ] = useState(false);
  const [
    openConfirmationDialogFileDelete,
    setOpenConfirmationDialogFileDelete
  ] = useState(false);

  const handleCancel = () => {
    if (isOpen) {
      if (hasInfoChanged()) {
        setOpenConfirmationDialogInfoCardCancel(true);
      } else {
        handleCloseModal();
      }
    }
  };

  const handleCloseConfirmDialogInfoCardDelete = (agree) => {
    if (agree) {
      deleteInfoCard(activeInfoCard.id);
    }
    setOpenConfirmationDialogInfoCardDelete(false);
  };

  const handleCloseConfirmDialogInfoCardCancel = async (agree) => {
    if (agree) {
      await handleSubmit();
    }
    handleCloseModal();
    setOpenConfirmationDialogInfoCardCancel(false);
  };

  const handleOpenFileDeleteConfirm = (fileId) => {
    setFileToDelete(fileId);
    setOpenConfirmationDialogFileDelete(true);
  };

  const handleCloseFileDeleteConfirm = (agree) => {
    if (agree && fileToDelete) {
      setFilesToDelete((prev) => [
        ...prev,
        fileIdsMap[activeInfoCard.id][fileToDelete].fileId
      ]);
      setFileIdsMap((prevMap) => {
        let newMap = { ...prevMap };
        if (
          newMap[activeInfoCard.id] &&
          newMap[activeInfoCard.id][fileToDelete]
        ) {
          delete newMap[activeInfoCard.id][fileToDelete];

          const files = [...newMap[activeInfoCard.id]];
          const newFilesOrder = files
            .filter((file) => file && Object.keys(file).length > 0)
            .map((file, index) => ({
              ...file,
              orderNumber: index + 1
            }));

          newMap = {
            ...prevMap,
            [activeInfoCard.id]: newFilesOrder
          };

          setReorderedFiles(newFilesOrder);
        }
        return newMap;
      });
    }
    setOpenConfirmationDialogFileDelete(false);
    setFileToDelete(null);
  };

  const areLinkedCardsEqual = () => {
    if (linkedCards.length === 0 && initialInfoCard.linkedCards.length === 0) {
      return true;
    }
    if (linkedCards.length !== initialInfoCard.linkedCards.length) {
      return false;
    }
    return linkedCards.every((card) => initialInfoCard.linkedCards.includes(card));
  };

  const hasInfoChanged = () => {
    if (isOpen) {
      const currentFiles = fileIdsMap[activeInfoCard.id] || [];
      const isOrderChanged = initialFilesForActiveInfoCard.some(
        (initialFile) => {
          const currentFile = currentFiles.find((file) => {
            if (file && initialFile) {
              return file.fileId === initialFile.fileId;
            } else {
              return false;
            }
          });
          return (
            !currentFile || initialFile.orderNumber !== currentFile.orderNumber
          );
        }
      );
      return (
        newFiles.length !== 0 ||
        isOrderChanged ||
        filesToDelete.length !== 0 ||
        documentType !== initialInfoCard.documentType ||
        serviceName !== initialInfoCard.serviceName ||
        organization !== initialInfoCard.organization ||
        specialization !== initialInfoCard.specialization ||
        doctor !== initialInfoCard.doctor ||
        date !== initialInfoCard.date ||
        parseFloat(price || "0") !== parseFloat(initialInfoCard.price || "0") ||
        comment !== initialInfoCard.comment ||
        !areLinkedCardsEqual()
      );
    }
    return false;
  };

  const validateFile = (file) => {
    if (!allowedFileTypes.includes(file.type)) {
      const errorMessage = "Цей тип файлу не підтримується: " + file.type;
      console.error(errorMessage);
      setSnackbarMessage(errorMessage);
      setOpenSnackbar(true);
      return false;
    }
    return true;
  };

  const handleFileChange = async (event) => {
    try {
      event.preventDefault();
      const newFiles = Array.from(event.target.files);

      const allFilesValid = newFiles.every(validateFile);
      if (!allFilesValid) {
        const errorMessage = "Один або кілька файлів мають непідтримуваний тип.";
        setError(errorMessage);
        setSnackbarMessage(errorMessage);
        setOpenSnackbar(true);
        return;
      }

      const newFilesSize = newFiles.reduce((total, file) => total + file.size, 0) / (1024 * 1024);
      const totalSize = newFilesSize + storageUsage;

      if (newFiles.some((file) => file.size / (1024 * 1024) > MAX_FILE_SIZE_MB)) {
        const errorMessage = `Один або кілька файлів перевищують максимальний розмір ${MAX_FILE_SIZE_MB} MB.`;
        setError(errorMessage);
        setSnackbarMessage(errorMessage);
        setOpenSnackbar(true);
        return;
      }

      if (totalSize > MAX_TOTAL_STORAGE_MB) {
        const errorMessage = `Перевищено ліміт виділеного місця для зберігання файлів. 
        З урахуванням доданих файлів, буде використано ${totalSize.toFixed(2)} MB 
        із доступних ${MAX_TOTAL_STORAGE_MB} MB.`;
        setError(errorMessage);
        setSnackbarMessage(errorMessage);
        setOpenSnackbar(true);
        return;
      }

      setNewFiles((prevFiles) => [...prevFiles, ...newFiles]);
      setError(null);
    } catch (error) {
      const errorMessage = "Не вдалося обробити файли: " + error.message;
      console.error("Error processing files: ", error);
      setError(errorMessage);
      setSnackbarMessage(errorMessage);
      setOpenSnackbar(true);
    } finally {
      event.target.value = null;
    }
  };

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    setLoading(true);
    setProgress(0);

    const infoCard = {
      documentType,
      serviceName,
      organization,
      specialization,
      doctor,
      date,
      price: parseFloat(price) || 0,
      comment,
      linkedCards
    };

    try {
      if (mode === "add") {
        await FileService.addInfoCard(newFiles, infoCard, setProgress, activeUser.id);
        await waitForUpload();
        await delay(500);
        handleCloseModal();
        setLoading(false);
        await fetchInfoCards(activeUser.id);
      } else {
        const deleteFiles = filesToDelete.map((id) =>
          FileService.deleteFile(id)
        );
        await Promise.all(deleteFiles);
        const updatedInfoCard = await FileService.updateInfoCard(
          activeInfoCard.id,
          infoCard,
          newFiles,
          reorderedFiles,
          setProgress
        );
        await waitForUpload();
        await delay(500);
        setLoading(false);
        const activeInfoCardId = activeInfoCard.id;
        const loadFiles = newFiles.length > 0 || filesToDelete.length > 0;
        await clearData();
        await fetchInfoCards(activeUser.id);
        setActiveInfoCard(updatedInfoCard);
        if (loadFiles || reorderedFiles.length > 0 || filesToDelete.length > 0) {
          await fetchImageUrls(activeInfoCard.id, updatedInfoCard);
        }
        loadAttachedFiles(activeInfoCardId, isMobile ? 1 : 4, false);
      }
    } catch (error) {
      const errorMessage = "Не вдалося обробити файл: " + error.message;
      console.error("Error processing file:", error);
      setError(errorMessage);
      setSnackbarMessage(errorMessage);
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
      fetchStorageUsage();
    }
  };

  const deleteInfoCard = async (infoCardId) => {
    setLoading(true);
    try {
      await handleInfoCardDelete(infoCardId);
      handleCloseModal();
      fetchInfoCards(activeUser.id);
    } catch (error) {
      const errorMessage = "Не вдалося видалити файл: " + error.message;
      console.error("Помилка при видаленні файлу:", error);
      setError(errorMessage);
      setSnackbarMessage(errorMessage);
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
      fetchStorageUsage();
    }
  };

  const handleCloseModal = () => {
    clearData();
    setIsOpen(false);
    setActiveInfoCard({});
  };

  const clearData = async () => {
    setActiveInfoCard({});
    setInitialInfoCard({});
    setInitialFilesForActiveInfoCard([]);
    setInitialInfoCard({})
    setNewFiles([]);
    setReorderedFiles([]);
    setFilesToDelete([]);
    setError(null);
    setDocumentType(0);
    setServiceName("");
    setOrganization("");
    setSpecialization("");
    setDoctor("");
    setDate(dayjs().format("YYYY-MM-DD"));
    setPrice("");
    setComment("");
    setLinkedCards([]);
  };

  const attachedFilesExists = () => {
    return (
      fileIdsMap &&
      fileIdsMap[activeInfoCard.id] &&
      fileIdsMap[activeInfoCard.id].length > 0
    );
  };

  const setupInitialData = () => {
    const initialData = {
      documentType: activeInfoCard.documentType || 0,
      serviceName: activeInfoCard.serviceName || "",
      organization: activeInfoCard.organization || "",
      specialization: activeInfoCard.specialization || "",
      doctor: activeInfoCard.doctor || "",
      date: activeInfoCard.date || dayjs().format("YYYY-MM-DD"),
      price: activeInfoCard.price || "",
      comment: activeInfoCard.comment || "",
      linkedCards: activeInfoCard.linkedCards || []
    };
    setDocumentType(initialData.documentType);
    setServiceName(initialData.serviceName);
    setOrganization(initialData.organization);
    setSpecialization(initialData.specialization);
    setDoctor(initialData.doctor);
    setDate(initialData.date);
    setPrice(initialData.price);
    setComment(initialData.comment);
    setLinkedCards(initialData.linkedCards);
    setInitialInfoCard(initialData);
  };

  useEffect(() => {
    if (isOpen) {
      setupInitialData();
    }
  }, [isOpen, activeInfoCard]);

  useEffect(() => {
    progressRef.current = progress;
  }, [progress]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <>
      {loading && (
        <LoaderOverlay>
          <CircularProgressWithLabel
            value={progress}
            size={isMobile ? 40 : 100}
          />
        </LoaderOverlay>
      )}

      <Dialog
        fullWidth={true}
        maxWidth={false}
        open={isOpen}
        onClose={handleCancel}
        keepMounted
      >
        <DialogTitle>
          {mode === "add" ? "Створення картки" : "Редагування картки"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCancel}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon fontSize="large"/>
        </IconButton>

        <DialogContent>
          {error && (
            <div className="alert alert-danger text-center" role="alert">
              {error}
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: isMobile ? "" : "flex",
                justifyContent: isMobile ? "center" : "space-between",
                flexDirection: "row",
                gap: 2
              }}
            >
              <Grid container spacing={2} marginTop="1px">
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel>Тип картки</InputLabel>
                    <Select
                      value={documentType}
                      onChange={(e) => setDocumentType(e.target.value)}
                      label="Тип картки"
                    >
                      {Object.entries(documentTypes).map(([value, label]) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    label="Назва послуги"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={serviceName}
                    onChange={(e) => setServiceName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    label="Клініка (організація)"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={organization}
                    onChange={(e) => setOrganization(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    label="Спеціалізація лікаря"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={specialization}
                    onChange={(e) => setSpecialization(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    label="ПІБ лікаря"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={doctor}
                    onChange={(e) => setDoctor(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    label="Ціна послуги"
                    variant="outlined"
                    size="small"
                    fullWidth
                    type="number"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <MDBInput
                    style={{ height: "40px" }}
                    label="Дата"
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </Grid>
              </Grid>
              <TextField
                label="Коментар"
                variant="outlined"
                size="small"
                multiline
                rows={4}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                sx={{ width: isMobile ? "100%" : "30%", mt: 2 }}
              />
            </Box>

            <LinkedCards infoCards={infoCards}
                         linkedCards={linkedCards}
                         setLinkCardModalOpen={setLinkCardModalOpen}
                         handleCancel={handleCancel}
                         handleInfoCardClick={handleInfoCardClick}
                         documentTypes={documentTypes}
            ></LinkedCards>

            {(newFiles.length > 0 || mode === "add") && (
              <FilesWillBeAdded
                newFiles={newFiles}
                setNewFiles={setNewFiles}
              ></FilesWillBeAdded>
            )}

            {mode === "edit" &&
              (attachedFilesExists() ||
                (!attachedFilesExists() && newFiles.length === 0)) && (
                <AttachedFiles
                  fileIdsMap={fileIdsMap}
                  setFileIdsMap={setFileIdsMap}
                  activeInfoCard={activeInfoCard}
                  setReorderedFiles={setReorderedFiles}
                  handleOpenFileDeleteConfirm={handleOpenFileDeleteConfirm}
                  fileProgress={fileProgress}
                  loadAttachedFiles={loadAttachedFiles}
                  activeCardFileIds={activeCardFileIds}
                  filesToDelete={filesToDelete}
                ></AttachedFiles>
              )}

            <DialogActions>
              <Grid
                container
                spacing={2}
                className={mode === "edit" ? "mt-1" : "mt-1"}
                style={{ display: "flex", justifyContent: "space-evenly" }}
              >
                <Grid item xs={12} sm={6} md={2.4}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="success"
                    type="submit"
                    startIcon={<SaveIcon/>}
                    disabled={
                      mode === "add" ? false : loading || !hasInfoChanged()
                    }
                  >
                    {loading
                      ? "Збереження..."
                      : mode === "add"
                        ? "Зберегти"
                        : hasInfoChanged()
                          ? "Зберегти"
                          : "Збережено"}
                  </Button>
                </Grid>
                {mode === "edit" && (
                  <Grid item xs={12} sm={6} md={2.4}>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      onClick={() => setLinkCardModalOpen(true)}
                      startIcon={<LinkIcon/>}
                    >
                      Зв'язати з іншою карткою
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={2.4}>
                  <Button
                    fullWidth
                    component="label"
                    role={undefined}
                    variant="outlined"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon/>}
                  >
                    Додати файли
                    <VisuallyHiddenInput
                      type="file"
                      multiple
                      accept=".pdf, .jpeg, .jpg, .png"
                      onChange={handleFileChange}
                    />
                  </Button>
                </Grid>
                {mode === "edit" && (
                  <Grid item xs={12} sm={6} md={2.4}>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="error"
                      startIcon={<DeleteIcon/>}
                      onClick={() =>
                        setOpenConfirmationDialogInfoCardDelete(true)
                      }
                    >
                      Видалити картку
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={2.4}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    type="button"
                    onClick={handleCancel}
                    startIcon={<CloseIcon/>}
                  >
                    Закрити
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openConfirmationDialogInfoCardCancel}
        onClose={() => setOpenConfirmationDialogInfoCardCancel(false)}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Підтвердіть дію</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenConfirmationDialogInfoCardCancel(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon fontSize="large"/>
        </IconButton>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="text-center"
          >
            Ви маєте не збережені зміни. Ви впевнені, що хочете закрити картку?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <MDBBtn
                block
                color="success"
                onClick={() => handleCloseConfirmDialogInfoCardCancel(true)}
              >
                Зберегти й закрити
              </MDBBtn>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <MDBBtn
                block
                color="danger"
                onClick={() => handleCloseConfirmDialogInfoCardCancel(false)}
              >
                Закрити без збереження
              </MDBBtn>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <MDBBtn
                block
                color="secondary"
                onClick={() => setOpenConfirmationDialogInfoCardCancel(false)}
              >
                Скасувати
              </MDBBtn>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirmationDialogInfoCardDelete}
        onClose={() => setOpenConfirmationDialogInfoCardDelete(false)}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Підтвердіть дію</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenConfirmationDialogInfoCardDelete(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon fontSize="large"/>
        </IconButton>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="text-center"
          >
            Ви впевнені, що хочете видалити цю картку?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <MDBBtn
                block
                color="danger"
                onClick={() => handleCloseConfirmDialogInfoCardDelete(true)}
              >
                Видалити
              </MDBBtn>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDBBtn
                block
                color="secondary"
                onClick={() => handleCloseConfirmDialogInfoCardDelete(false)}
              >
                Скасувати
              </MDBBtn>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirmationDialogFileDelete}
        onClose={() => setOpenConfirmationDialogFileDelete(false)}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Підтвердіть дію</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenConfirmationDialogFileDelete(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon fontSize="large"/>
        </IconButton>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="text-center"
          >
            Ви впевнені, що хочете видалити цей файл?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <MDBBtn
                block
                color="danger"
                onClick={() => handleCloseFileDeleteConfirm(true)}
              >
                Видалити
              </MDBBtn>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDBBtn
                block
                color="secondary"
                onClick={() => handleCloseFileDeleteConfirm(false)}
              >
                Скасувати
              </MDBBtn>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <LinkedCardsButton infoCards={infoCards}
                         linkCardModalOpen={linkCardModalOpen}
                         setLinkCardModalOpen={setLinkCardModalOpen}
                         linkedCards={linkedCards}
                         setLinkedCards={setLinkedCards}
                         activeInfoCard={activeInfoCard}
      ></LinkedCardsButton>

      <Snackbar open={openSnackbar} autoHideDuration={15000} onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <MuiAlert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default InfoCardModal;
