import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select
} from "@mui/material";
import React from "react";

const LinkedCardsButton = ({
                             infoCards,
                             linkCardModalOpen,
                             setLinkCardModalOpen,
                             linkedCards,
                             setLinkedCards,
                             activeInfoCard
                           }) => {
  return (
    <Dialog
      open={linkCardModalOpen}
      onClose={() => setLinkCardModalOpen(false)}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Зв'язати з іншою карткою</DialogTitle>
      <DialogContent>
        <FormControl variant="outlined" fullWidth size="small" className="mt-2">
          <InputLabel>Обрати картку для лінкування</InputLabel>
          <Select
            multiple
            value={linkedCards}
            onChange={(e) => setLinkedCards(e.target.value)}
            renderValue={(selected) => selected.join(', ')}
            label="Обрати картку для лінкування"
          >
            {infoCards
              .filter(card => card.id !== activeInfoCard.id)
              .map((card) => (
                <MenuItem key={card.id} value={card.id}>
                  <Checkbox checked={linkedCards.includes(card.id)}/>
                  <ListItemText primary={card.cardName}/>
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setLinkCardModalOpen(false)}
          color="secondary"
        >
          Закрити
        </Button>
        <Button
          onClick={() => {
            setLinkCardModalOpen(false);
          }}
          color="primary"
        >
          Зберегти
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LinkedCardsButton;
