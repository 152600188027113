import { MDBProgress, MDBProgressBar } from "mdb-react-ui-kit";
import React from "react";

const StorageUsage = ({ storageUsage, storageUsageError }) => {
  const MAX_TOTAL_STORAGE_MB = process.env.REACT_APP_USER_STORAGE_LIMIT;
  const percentage = (storageUsage / MAX_TOTAL_STORAGE_MB) * 100;

  if (storageUsageError) {
    return <div>Error: {storageUsageError}</div>;
  }

  if (storageUsage === null) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ marginTop: "-5px" }} key={storageUsage}>
      <p style={{ marginBottom: "0px" }}>
        Використано {storageUsage} MB із {MAX_TOTAL_STORAGE_MB} MB
      </p>
      <MDBProgress height="15">
        <MDBProgressBar
          key={storageUsage}
          width={percentage}
          valuemin={0}
          valuemax={100}
          bgColor={storageUsage >= MAX_TOTAL_STORAGE_MB ? "danger" : "success"}
        ></MDBProgressBar>
      </MDBProgress>
    </div>
  );
};

export default StorageUsage;
