import axios from "axios";
import { authEvents } from '../../context/AuthEventEmitter';

const getHostName = () => process.env.REACT_APP_HOST_NAME_BACKEND;

const axiosClient = axios.create({
  baseURL: `${getHostName()}/api/v1/`,
});

axiosClient.interceptors.request.use(
  (config) => {
    if (config.headers.Authorization) {
      const token = config.headers.Authorization.split(" ")[1];
      try {
        const data = parseJwt(token);
        if (Date.now() > data.exp * 1000) {
          console.log("Token expired");
          authEvents.emit('logout');
        }
      } catch (error) {
        console.error("Error parsing JWT", error);
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const parseJwt = (token) => {
  if (!token) {
    return;
  }
  const parts = token.split('.');
  if (parts.length !== 3) {
    throw new Error("The token is invalid");
  }

  const base64Url = parts[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  try {
    return JSON.parse(window.atob(base64));
  } catch (e) {
    throw new Error("Failed to parse JWT: invalid token format");
  }
};

export default axiosClient;
