import {
  MDBCollapse,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarNav,
  MDBNavbarToggler
} from "mdb-react-ui-kit";
import React, { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useLocation } from "react-router-dom";
import Login from "../auth/Login";
import MenuIcon from "@mui/icons-material/Menu";

const Header = () => {
  const { isMobile } = useAuth();
  const [showBasic, setShowBasic] = useState(false);
  const logo = process.env.PUBLIC_URL + "/logo512.png";
  const environment = process.env.REACT_APP_ENV;
  const location = useLocation();

  return (
    <MDBNavbar expand="lg" className="h-1 p-1" light bgColor="light">
      <MDBNavbarBrand href="/" className="ms-5">
        <img src={logo} height="30" alt="" loading="lazy" className="me-2"/>
        {environment === "prod" ? "Life Data Hub" : "Life Data Hub - Env: " + environment}
      </MDBNavbarBrand>
      <MDBNavbarToggler
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        onClick={() => setShowBasic(!showBasic)}
      >
        <MenuIcon/>
      </MDBNavbarToggler>
      <MDBCollapse navbar open={showBasic}>
        <MDBNavbarNav className="d-flex justify-content-end align-items-center">
          <MDBNavbarItem>
            <MDBNavbarLink
              active={location.pathname === "/"}
              aria-current="page"
              href="/"
            >
              Головна
            </MDBNavbarLink>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <MDBNavbarLink
              active={location.pathname === "/medicine"}
              aria-current="page"
              href="/medicine"
            >
              Мої документи
            </MDBNavbarLink>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <MDBNavbarLink
              className={isMobile ? "" : "me-5"}
              active={location.pathname === "/medicine/analytics"}
              aria-current="page"
              href="/medicine/analytics"
            >
              Аналітика
            </MDBNavbarLink>
          </MDBNavbarItem>
          <Login setShowBasic={setShowBasic}></Login>
        </MDBNavbarNav>
      </MDBCollapse>
    </MDBNavbar>
  );
};

export default Header;
