import axiosClient from "../config/AxiosService";

const FamilyService = {

  async getFamilyMembers(familyId) {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosClient.get(`/family/${familyId}/member/get-all`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      return response.data;
    } catch (error) {
      console.error("Failed to get family members:", error);
      throw error;
    }
  },

  async createFamilyMember(memberName, avatarFile) {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const formData = new FormData();
      formData.append("memberName", memberName);
      if (avatarFile) {
        formData.append("avatar", avatarFile);
      }
      const response = await axiosClient.post(`/family/member/create`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      return response.data;
    } catch (error) {
      console.error("Failed to create new family member:", error);
      throw error;
    }
  },

  async updateFamilyMember(memberId, memberName, avatarFile) {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const formData = new FormData();
      formData.append("memberId", memberId);
      if (memberName) {
        formData.append("memberName", memberName);
      }
      if (avatarFile) {
        formData.append("avatar", avatarFile);
      }
      const response = await axiosClient.post(`/family/member/${memberId}/update`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      return response.data;
    } catch (error) {
      console.error("Failed to create new family member:", error);
      throw error;
    }
  },

  async deleteFamilyMember(memberId) {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosClient.get(`/family/member/${memberId}/delete`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      return response.data;
    } catch (error) {
      console.error("Failed to create new family member:", error);
      throw error;
    }
  },

  async updateFamilyOrder(updatedFamilyMembers) {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const formData = new FormData();
      Array.from(updatedFamilyMembers).forEach((familyMember) => {
        formData.append(
          "familyMember",
          new Blob([JSON.stringify(familyMember)], { type: "application/json" })
        );
      });
      const response = await axiosClient.post(`/family/member/ordering/update`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      return response.data;
    } catch (error) {
      console.error("Failed to create new family member:", error);
      throw error;
    }
  }
};

export default FamilyService;
