import React, { useEffect, useState } from "react";
import {
  Alert,
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import FamilyService from "../api/family/FamilyService";
import { useAuth } from "../context/AuthContext";
import { MDBBtn } from "mdb-react-ui-kit";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CustomSnackbar from "../components/common/CustomSnackbar";

const FamilyPage = () => {
  const { isLoggedIn } = useAuth();
  const [familyMembers, setFamilyMembers] = useState([]);
  const [reorderedFamilyMembers, setReorderedFamilyMembers] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [currentMember, setCurrentMember] = useState(null);
  const [avatarFile, setAvatarFile] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [error, setError] = useState(null);
  const [nameError, setNameError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [snackbarSeverity, setSnackbarSeverity] = useState(null);

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    const reorderedMembers = Array.from(familyMembers);
    const [movedMember] = reorderedMembers.splice(source.index, 1);
    reorderedMembers.splice(destination.index, 0, movedMember);
    const updatedFamilyMembers = reorderedMembers.map((member, index) => ({
      ...member,
      familyOrderNumber: index + 1
    }));
    setFamilyMembers(updatedFamilyMembers);
    setReorderedFamilyMembers(updatedFamilyMembers);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleReorderedFamilyMembersSave = async () => {
    try {
      await FamilyService.updateFamilyOrder(reorderedFamilyMembers);
      await fetchFamilyMembers();
      showSnackbar("Сортування членів родини успішно змінено!", "success");
    } catch (error) {
      console.error("Failed to update family members ordering:", error);
      showSnackbar("Не вдалося змінити сортування членів родини: " + error.message, "error");
    } finally {
      setReorderedFamilyMembers([]);
    }
  };

  const fetchFamilyMembers = async () => {
    const familyId = localStorage.getItem("familyId");
    if (familyId) {
      try {
        setLoading(true);
        const familyMembers = await FamilyService.getFamilyMembers(familyId);
        const membersWithAvatars = familyMembers.map((member) => {
          if (member.avatar) {
            const binaryData = atob(member.avatar);
            const bytes = new Uint8Array(binaryData.length);
            for (let i = 0; i < binaryData.length; i++) {
              bytes[i] = binaryData.charCodeAt(i);
            }
            const blob = new Blob([bytes], { type: 'image/jpeg' });
            const avatarUrl = URL.createObjectURL(blob);
            return { ...member, avatarUrl };
          }
          return member;
        });
        const sortedMembers = membersWithAvatars.sort((a, b) => a.familyOrderNumber - b.familyOrderNumber);
        setFamilyMembers(sortedMembers);
      } catch (error) {
        console.error("Failed to fetch family members:", error);
        setError("Не вдалося завантажити список членів родини: " + error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setAvatarFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setCurrentMember((prevMember) => ({
          ...prevMember,
          avatarUrl: reader.result
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEdit = (member) => {
    setCurrentMember(member);
    setOpenEditDialog(true);
  };

  const handleDelete = (member) => {
    setCurrentMember(member);
    setOpenDeleteDialog(true);
  };

  const handleSave = async () => {
    const modeEdit = !!currentMember?.id;
    if (!currentMember?.name || currentMember.name.trim() === "") {
      setNameError(true);
      return;
    }
    try {
      setLoading(true);
      setNameError(false);
      if (modeEdit) {
        await FamilyService.updateFamilyMember(currentMember.id, currentMember.name, avatarFile);
      } else {
        const response = await FamilyService.createFamilyMember(currentMember.name, avatarFile);
        const localFamilyId = localStorage.getItem("familyId");
        if (!localFamilyId && response.familyId) {
          localStorage.setItem("familyId", response.familyId);
        }
      }
      await fetchFamilyMembers();
      setOpenEditDialog(false);
    } catch (error) {
      console.error("Failed to create family member:", error);
    } finally {
      setCurrentMember(null);
      setAvatarFile(null);
      setLoading(false);
    }
  };

  const handleConfirmDelete = async () => {
    await FamilyService.deleteFamilyMember(currentMember.id);
    await fetchFamilyMembers();
    setOpenDeleteDialog(false);
  };

  const handleAddNewMemberButtonClick = async () => {
    setCurrentMember(null);
    setOpenEditDialog(true);
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchFamilyMembers();
    }
  }, [isLoggedIn]);

  const getMemberName = (member) => {
    if (member) {
      const userId = localStorage.getItem("userId");
      if (member.id.toString() === userId) {
        return "Я: " + member.name;
      } else {
        return "Член родини: " + member.name;
      }
    }
  };

  const isMainUser = (member) => {
    if (member) {
      const userId = localStorage.getItem("userId");
      if (member.id.toString() === userId) {
        return true;
      }
    }
    return false;
  };

  const handleCloseDialogCreateEditFamilyMember = () => {
    setOpenEditDialog(false);
    setNameError(false);
  }

  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <CircularProgress/>
        </div>
      )}
      <Box
        sx={{
          padding: 4,
          width: "100%",
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
          Моя родина
        </Typography>

        {error && (
          <Alert severity="error" sx={{ textAlign: "center", width: "100%", marginTop: 2 }}>
            {error}
          </Alert>
        )}

        {!error && !loading && familyMembers.length === 0 && (
          <Typography variant="h6" sx={{ marginTop: 2, textAlign: "center" }}>
            Додайте члена родини, щоб створити родину!
          </Typography>
        )}

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="familyMembersDroppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {familyMembers.map((member, index) => (
                  <Draggable key={member.id} draggableId={member.id.toString()} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Card key={member.id}
                              sx={{ display: "flex", justifyContent: "space-between", width: "100%", mb: 1 }}>
                          <CardContent sx={{ display: "flex", alignItems: "center" }}>
                            {member.avatarUrl ? (
                              <Avatar src={member.avatarUrl} alt={member.name} sx={{ marginRight: 2 }}/>
                            ) : (
                              <Avatar sx={{ marginRight: 2 }}>
                                {member?.name?.charAt(0) || <AccountCircleIcon/>}
                              </Avatar>
                            )}
                            <Typography variant="h6">{getMemberName(member)}</Typography>
                          </CardContent>
                          {!isMainUser(member) && (
                            <CardActions>
                              <IconButton onClick={() => handleEdit(member)}>
                                <EditIcon/>
                              </IconButton>
                              <IconButton onClick={() => handleDelete(member)}>
                                <DeleteIcon/>
                              </IconButton>
                            </CardActions>
                          )}
                        </Card>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {reorderedFamilyMembers.length > 0 && (
          <Button sx={{ marginTop: 1.5, width: { sm: '420px', xs: '100%' } }} variant="contained" color="success"
                  onClick={handleReorderedFamilyMembersSave}>
            Зберегти нове сортування
          </Button>
        )}

        <Button
          sx={{ marginTop: 2, width: { sm: '420px', xs: '100%' } }}
          variant="contained"
          startIcon={<AddIcon/>}
          onClick={handleAddNewMemberButtonClick}
        >
          Додати члена родини
        </Button>

        {/* Діалог редагування/додавання */}
        <Dialog open={openEditDialog} onClose={handleCloseDialogCreateEditFamilyMember} maxWidth="sm" fullWidth>
          <DialogTitle>{currentMember?.id ? "Редагувати члена родини" : "Додати члена родини"}</DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                  <IconButton
                    onClick={() => document.getElementById('avatar-input').click()}
                    sx={{
                      backgroundColor: 'white',
                      boxShadow: 1,
                      '&:hover': {
                        backgroundColor: 'lightgray'
                      }
                    }}
                  >
                    <EditIcon fontSize="small"/>
                  </IconButton>
                }
              >
                <Avatar
                  sx={{ width: 100, height: 100 }}
                  src={currentMember?.avatarUrl || ""}
                  alt={currentMember?.name || ""}
                />
              </Badge>
              <input
                id="avatar-input"
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleAvatarChange}
              />
            </Box>
            <TextField
              className="mt-4"
              autoFocus
              margin="dense"
              label="Ім'я"
              type="text"
              fullWidth
              required={true}
              value={currentMember?.name || ""}
              onChange={(e) => {
                setCurrentMember({ ...currentMember, name: e.target.value });
                if (e.target.value.trim() !== "") setNameError(false);
              }}
              error={nameError}
              helperText={nameError ? "Ім'я не може бути порожнім" : ""}
            />
          </DialogContent>
          <DialogActions>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <MDBBtn
                  block
                  color="success"
                  onClick={handleSave}
                >
                  Зберегти
                </MDBBtn>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <MDBBtn
                  block
                  color="secondary"
                  onClick={handleCloseDialogCreateEditFamilyMember}
                >
                  Скасувати
                </MDBBtn>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>

        {/* Діалог підтвердження видалення */}
        <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
          <DialogTitle>Видалити члена родини</DialogTitle>
          <DialogContent>
            <Typography>
              Ви впевнені, що хочете видалити {currentMember?.name}?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <MDBBtn
                  block
                  color="danger"
                  onClick={handleConfirmDelete}
                >
                  Видалити
                </MDBBtn>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <MDBBtn
                  block
                  color="secondary"
                  onClick={() => setOpenDeleteDialog(false)}
                >
                  Скасувати
                </MDBBtn>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        <CustomSnackbar
          message={snackbarMessage}
          severity={snackbarSeverity}
          open={openSnackbar}
          onClose={handleSnackbarClose}
        />
      </Box>
    </>
  );
};

export default FamilyPage;
