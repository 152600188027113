import axiosClient from "../config/AxiosService";

const FileService = {
  async getUserStorageUsage() {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosClient.get(`/user/storage-usage`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      return response.data;
    } catch (error) {
      console.error("Failed to fetch storage usage:", error);
      throw error;
    }
  },

  async getUserStorageUsageByUserId(userId) {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosClient.get(`/user/storage-usage/${userId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      return response.data;
    } catch (error) {
      console.error("Failed to fetch storage usage:", error);
      throw error;
    }
  },

  async getFileIdsForInfoCard(infoCardId) {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosClient.get(
        `/medicine/info-card/${infoCardId}/file-ids`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error("Помилка при отриманні ID файлів для картки:", error);
      throw error;
    }
  },

  async viewFile(id) {
    try {
      const accessToken = localStorage.getItem("accessToken");
      return await axiosClient.get(`/medicine/files/view/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
    } catch (error) {
      console.error("Помилка при отриманні файлу:", error);
      throw error;
    }
  },

  async getAllInfoCards(activeUserId) {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosClient.get(`/medicine/${activeUserId}/info-cards`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      return response.data;
    } catch (error) {
      console.error("Помилка при отриманні інформаційних карток:", error);
      throw error;
    }
  },

  async updateInfoCard(id, infoCard, newFiles, reorderedFiles, setProgress) {
    try {
      const formData = new FormData();
      Array.from(newFiles).forEach((file) => {
        formData.append("newFiles", file);
      });
      formData.append(
        "reorderedFiles",
        new Blob([JSON.stringify(reorderedFiles)], { type: "application/json" })
      );
      formData.append(
        "infoCard",
        new Blob([JSON.stringify(infoCard)], { type: "application/json" })
      );
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosClient.patch(
        `/medicine/info-cards/update/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          onUploadProgress: (progressEvent) => {
            const total = progressEvent.total;
            const current = progressEvent.loaded;
            const progress = Math.round((current / total) * 100);
            setProgress(progress);
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error("Помилка при оновленні картки:", error);
      throw error;
    }
  },

  async addInfoCard(files, infoCard, setProgress, activeUserId) {
    try {
      const formData = new FormData();
      Array.from(files).forEach((file) => {
        formData.append("files", file);
      });
      formData.append(
        "infoCard",
        new Blob([JSON.stringify(infoCard)], { type: "application/json" })
      );
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosClient.post(
        `/medicine/${activeUserId}/info-cards/add`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          onUploadProgress: (progressEvent) => {
            const total = progressEvent.total;
            const current = progressEvent.loaded;
            const progress = Math.round((current / total) * 100);
            setProgress(progress);
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error("Помилка при створенні картки:", error);
      throw error;
    }
  },

  async deleteInfoCard(id) {
    try {
      const accessToken = localStorage.getItem("accessToken");
      await axiosClient.delete(`/medicine/info-cards/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
    } catch (error) {
      console.error("Помилка при видаленні картки:", error);
      throw error;
    }
  },

  async deleteFile(id) {
    try {
      const accessToken = localStorage.getItem("accessToken");
      await axiosClient.delete(`/medicine/files/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
    } catch (error) {
      console.error("Помилка при видаленні файлу:", error);
      throw error;
    }
  },

  async updateFavorite(id, favorite) {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosClient.patch(
        `/medicine/info-cards/favorite/${id}`,
        JSON.stringify(favorite),
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json"
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error("Помилка при оновленні картки:", error);
      throw error;
    }
  }
};

export default FileService;
