import { CircularProgress, Grid, IconButton } from "@mui/material";
import {
  MDBBtn,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle
} from "mdb-react-ui-kit";
import React, { useState } from "react";
import AuthService from "../../api/auth/AuthService";
import { useAuth } from "../../context/AuthContext";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

const SignUpModal = ({ isOpenSignUpModal, setIsOpenSignUpModal }) => {
  const Auth = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [authCode, setAuthCode] = useState("");
  const [name, setName] = useState("");
  const [validationError, setValidationError] = useState(null);
  const [emailValid, setEmailValid] = useState(true);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    let valid = true;

    if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      setEmailValid(false);
      setValidationError("Введіть коректний емейл");
      valid = false;
    } else {
      setEmailValid(true);
    }

    if (password !== confirmPassword) {
      setPasswordsMatch(false);
      setValidationError("Паролі не співпадають");
      valid = false;
    } else {
      setPasswordsMatch(true);
    }

    if (valid) {
      setValidationError(null);
      const userData = { email, password, authCode, name };
      handleSignUp(userData);
    }
  };

  const closeSignUpModal = () => {
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setAuthCode("");
    setError(null);
    setValidationError(null);
    setIsOpenSignUpModal(false);
    setShowPassword(false);
    setShowConfirmPassword(false);
  };

  const handleSignUp = (userData) => {
    setLoading(true);
    AuthService.signup(userData)
      .then((response) => {
        Auth.userLogin(response.data);
        closeSignUpModal();
      })
      .catch((error) => {
        console.error("Помилка при реєстрації:", error);
        if (error.response && error.response.status === 401) {
          setError("Неправильний код реєстрації.");
        } else if (error.response && error.response.data) {
          setError(error.response.data);
        } else {
          setError(error.message || "An unexpected error occurred");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <CircularProgress/>
        </div>
      )}
      <MDBModal open={isOpenSignUpModal} tabIndex="-1" staticBackdrop>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Реєстрація</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={closeSignUpModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {error && (
                <div className="alert alert-danger text-center" role="alert">
                  {error}
                </div>
              )}
              <div className="alert alert-warning text-center" role="alert">
                Проект знаходиться в режимі закритого бета тестування
              </div>
              <form onSubmit={handleSubmit}>
                {validationError && (
                  <div className="alert alert-danger text-center" role="alert">
                    {validationError}
                  </div>
                )}
                <MDBInput
                  className={`mb-4 ${!emailValid ? "is-invalid" : ""}`}
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  label="Email"
                  required
                />
                <div className="mb-4 position-relative">
                  <MDBInput
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    label="Пароль"
                    required
                    className={!passwordsMatch ? "is-invalid" : ""}
                  />
                  <IconButton
                    size="small"
                    onClick={() => setShowPassword(!showPassword)}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      right: 8,
                      transform: "translateY(-50%)",
                      cursor: "pointer"
                    }}
                  >
                    {showPassword ? <VisibilityOff fontSize="small"/> : <Visibility fontSize="small"/>}
                  </IconButton>
                </div>
                <div className="mb-4 position-relative">
                  <MDBInput
                    type={showConfirmPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    label="Підтвердіть пароль"
                    required
                    className={!passwordsMatch ? "is-invalid" : ""}
                  />
                  <IconButton
                    size="small"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      right: 8,
                      transform: "translateY(-50%)",
                      cursor: "pointer"
                    }}
                  >
                    {showConfirmPassword ? <VisibilityOff fontSize="small"/> : <Visibility fontSize="small"/>}
                  </IconButton>
                </div>
                <MDBInput
                  className="mb-3"
                  type="text"
                  value={name}
                  id="name"
                  label="Ім'я"
                  required
                  name="name"
                  onChange={(e) => setName(e.target.value)}
                />
                <MDBInput
                  className="mb-3"
                  type="text"
                  value={authCode}
                  id="authCodeSignUp"
                  label="Код реєстрації"
                  required
                  name="authCodeSignUp"
                  onChange={(e) => setAuthCode(e.target.value)}
                />
                <MDBModalFooter>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <MDBBtn
                        block
                        color="secondary"
                        onClick={closeSignUpModal}
                      >
                        Скасувати
                      </MDBBtn>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <MDBBtn
                        block
                        color="primary"
                        type="submit"
                      >
                        Зареєструватися
                      </MDBBtn>
                    </Grid>
                  </Grid>
                </MDBModalFooter>
              </form>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default SignUpModal;
