import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Card, CardMedia, IconButton, Typography } from "@mui/material";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useAuth } from "../../context/AuthContext";

const FilesWillBeAdded = ({ newFiles, setNewFiles }) => {
  const { isMobile } = useAuth();
  const pdfPlaceholderImage = process.env.PUBLIC_URL + "/images/pdf-icon-2.webp";
  const defaultPlaceholderImage = process.env.PUBLIC_URL + "/images/default-placeholder-2.webp";
  const imageWidth = "275px";
  const imageHeight = "388px";

  const handleLocalFileDelete = (index) => {
    setNewFiles((prevFiles) => prevFiles.filter((_, idx) => idx !== index));
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;

    const reorderedFiles = Array.from(newFiles);
    const [movedFile] = reorderedFiles.splice(source.index, 1);
    reorderedFiles.splice(destination.index, 0, movedFile);

    setNewFiles(reorderedFiles);
  };

  return (
    <>
      <Typography
        variant="h6"
        className="d-flex justify-content-center align-items-center text-center mt-2 mb-2"
      >
        {newFiles.length > 0
          ? "Наступні файли будуть прикріплені"
          : "Прикріплені файли відсутні"}
      </Typography>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-files" direction={isMobile ? "vertical" : "horizontal"}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                display: isMobile ? "" : "flex",
                overflowX: isMobile ? "clip" : "auto",
                overflowY: isMobile ? "auto" : "clip",
                minHeight: isMobile ? "100%" : "300px",
                padding: isMobile ? "0 2px" : "0 8px"
              }}
            >
              {newFiles.map((file, index) => {
                const isImage = file.type.startsWith("image/");
                const previewUrl = isImage
                  ? URL.createObjectURL(file) || defaultPlaceholderImage
                  : pdfPlaceholderImage;

                const handlePreview = () => {
                  const fileUrl = URL.createObjectURL(file);
                  window.open(fileUrl, "_blank");
                };

                return (
                  <Draggable
                    key={index}
                    draggableId={String(index)}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          marginRight: isMobile ? "" : "20px",
                          marginBottom: "20px"
                        }}
                      >
                        <Card
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            height: imageHeight,
                            width: "100%",
                            userSelect: "none",
                            position: "relative"
                          }}
                        >
                          <Box
                            style={{
                              width: imageWidth,
                              height: imageHeight,
                              overflow: "auto",
                              padding: "4px",
                              boxSizing: "border-box"
                            }}
                          >
                            <CardMedia
                              component="img"
                              height="140"
                              image={previewUrl}
                              alt="File preview"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                objectPosition: "left top",
                                borderRadius: "5px"
                              }}
                            />
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            padding={1}
                          >
                            <IconButton
                              onClick={handlePreview}
                              className={isMobile ? "mb-3" : ""}
                            >
                              <VisibilityIcon/>
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              onClick={() => handleLocalFileDelete(index)}
                            >
                              <DeleteIcon/>
                            </IconButton>
                          </Box>
                        </Card>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default FilesWillBeAdded;
