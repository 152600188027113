import CryptoJS from "crypto-js";

const encryptionSecret = process.env.REACT_APP_ENCRYPTION_SECRET;

const EncryptionService = {

  encrypt(data) {
    const secretKey = CryptoJS.enc.Base64.parse(encryptionSecret);
    const iv = CryptoJS.lib.WordArray.random(16);
    const encrypted = CryptoJS.AES.encrypt(data, secretKey, { iv: iv }).toString();
    const ivAndEncrypted = CryptoJS.enc.Base64.stringify(iv.concat(CryptoJS.enc.Base64.parse(encrypted)));
    return ivAndEncrypted;
  }
}

export default EncryptionService;
