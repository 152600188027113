import { Avatar, Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { MDBContainer } from "mdb-react-ui-kit";
import React, { useCallback, useEffect, useRef, useState } from "react";
import FileService from "../api/files/FileService";
import InfoCardFilters from "../components/filters/InfoCardFilters";
import useInfoCardFilter from "../components/filters/useInfoCardFilter";
import { useAuth } from "../context/AuthContext";
import InfoCardModal from "../components/cards/InfoCardModal";
import InfoCard from "../components/cards/InfoCard";
import { useInView } from "react-intersection-observer";
import FamilyService from "../api/family/FamilyService";
import StorageUsage from "../components/files/StorageUsage";
import UserService from "../api/user/UserService";

const initialFilters = {
  organization: "",
  specialization: "",
  doctor: "",
  startDate: "",
  endDate: ""
};

const Medicine = () => {
  const { isLoggedIn, isLoading, isMobile } = useAuth();
  const [mode, setMode] = useState("");
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(initialFilters);
  const [searchQuery, setSearchQuery] = useState("");
  const [infoCards, setInfoCards] = useState([]);
  const [favoriteCards, setFavoriteCards] = useState([]);
  const { filteredInfoCards, getUniqueValues } = useInfoCardFilter(infoCards, filters, searchQuery);
  const [activeInfoCard, setActiveInfoCard] = useState({});
  const [error, setError] = useState(null);
  const [mainImageUrls, setMainImageUrls] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [fileIdsMap, setFileIdsMap] = useState({});
  const [fileProgress, setFileProgress] = useState(null);
  const [activeCardFileIds, setActiveCardFileIds] = useState(0);
  const [initialFilesForActiveInfoCard, setInitialFilesForActiveInfoCard] = useState([]);
  const [storageUsage, setStorageUsage] = useState(null);
  const [storageUsageError, setStorageUsageError] = useState(null);
  const [users, setUsers] = useState([]);
  const [activeUser, setActiveUser] = useState(null);
  const [page, setPage] = useState(1);
  const itemsPerPage = 8;

  const { ref, inView } = useInView({
    threshold: 0.01
  });

  const pdfPlaceholderImage = process.env.PUBLIC_URL + "/images/pdf-icon-2.webp";
  const defaultPlaceholderImage = process.env.PUBLIC_URL + "/images/default-placeholder-2.webp";

  const documentTypes = [
    "Не заповнено",
    "Медичний висновок",
    "Лабораторне дослідження",
    "Комплексна діагностика",
    "Лікарняний",
    "Рецепт",
    "Направлення",
    "Щеплення",
    "Майбутня подія",
    "Плановий візит"
  ];

  const infoCardsRef = useRef(infoCards);

  const base64ToBlob = (base64Data, contentType = 'image/jpeg') => {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  };

  const fetchFamilyMembers = async () => {
    const familyId = localStorage.getItem("familyId");
    if (familyId) {
      try {
        const familyMembers = await FamilyService.getFamilyMembers(familyId);
        const membersWithAvatars = familyMembers.map((member) => {
          if (member.avatar) {
            const blob = base64ToBlob(member.avatar);
            const avatarUrl = URL.createObjectURL(blob);
            return { ...member, avatarUrl };
          }
          return member;
        });
        setUsers(membersWithAvatars);
      } catch (error) {
        console.error("Failed to fetch family members:", error);
        setError("Не вдалося завантажити список членів родини: " + error.message);
      }
    }
  };

  const handleUserSwitch = (user) => {
    setActiveUser(user);
    localStorage.setItem("activeUserId", user.id);
  };

  const fetchStorageUsage = async () => {
    try {
      const usage = await FileService.getUserStorageUsage();
      setStorageUsage(usage);
    } catch (err) {
      setStorageUsageError(err.message);
    }
  };

  const waitForCard = (cardId, timeout = 1000) => {
    return new Promise((resolve, reject) => {
      const startTime = Date.now();

      const checkCardExistence = () => {
        const infoCard = infoCardsRef.current.find(
          (card) => card.id === cardId
        );
        if (infoCard) {
          resolve(infoCard);
        } else if (Date.now() - startTime > timeout) {
          reject(new Error("Timeout waiting for the card to appear"));
        } else {
          setTimeout(checkCardExistence, 100);
        }
      };

      checkCardExistence();
    });
  };

  const handleInfoCardClick = async (
    cardId,
    loadFiles,
    fullRefresh,
    openModal = true
  ) => {
    setError(null);
    const activeInfoCard = await waitForCard(cardId);
    setActiveInfoCard(activeInfoCard);
    setMode("edit");
    if (openModal) {
      setShowModal(true);
    }
    const deepCopiedFiles = JSON.parse(JSON.stringify(fileIdsMap[cardId] || []));
    setInitialFilesForActiveInfoCard(deepCopiedFiles);
    if (loadFiles) {
      await loadAttachedFiles(cardId, isMobile ? 1 : 4, fullRefresh);
    }
  };

  const loadAttachedFiles = async (cardId, filesCount, fullRefresh) => {
    try {
      const existingFileIds = fullRefresh
        ? []
        : (fileIdsMap[cardId] || []).map((file) => file.fileId);
      const activeInfoCard = infoCards.find((card) => card.id === cardId);
      const fileIds = await FileService.getFileIdsForInfoCard(activeInfoCard.id);
      setActiveCardFileIds(fileIds);

      if (filesCount && existingFileIds.length >= filesCount && !fullRefresh) {
        return;
      }

      const missingFileIds = fileIds.filter(
        (fileId) => !existingFileIds.includes(fileId)
      );

      const totalFilesCount =
        !filesCount || missingFileIds.length < filesCount
          ? missingFileIds.length
          : filesCount;
      let newFileIdsArray = fullRefresh ? [] : [...(fileIdsMap[cardId] || [])];
      let loadedFilesCount = 0;

      for (let index = 0; index < totalFilesCount; index++) {
        const fileId = missingFileIds[index];
        newFileIdsArray.push({
          fileId: fileId,
          url: null,
          contentType: "image",
          orderNumber: existingFileIds.length + index + 1,
          fileName: null
        });
        loadedFilesCount++;
        setFileProgress((loadedFilesCount / (totalFilesCount * 2)) * 100);
      }

      setFileIdsMap((prev) => ({
        ...prev,
        [activeInfoCard.id]: newFileIdsArray
      }));

      const loadFile = async (fileId) => {
        try {
          const response = await FileService.viewFile(fileId);
          const fileModel = response.data;
          const contentType = fileModel.contentType;
          const orderNumber = fileModel.orderNumber;
          const base64Data = fileModel.data;
          const binaryData = atob(base64Data);
          const bytes = new Uint8Array(binaryData.length);
          for (let i = 0; i < binaryData.length; i++) {
            bytes[i] = binaryData.charCodeAt(i);
          }
          const blob = new Blob([bytes], { type: contentType });
          const blobUrl = URL.createObjectURL(blob);
          const fileName = fileModel.fileName;

          return { fileId, blobUrl, contentType, orderNumber, fileName };
        } catch (error) {
          console.error(`Error loading file ${fileId}:`, error);
          return null;
        }
      };

      const filePromises = missingFileIds
        .slice(0, totalFilesCount)
        .map(loadFile);

      filePromises.forEach((promise) => {
        promise.then((loadedFile) => {
          if (loadedFile) {
            setFileIdsMap((prev) => {
              const updatedFiles = prev[activeInfoCard.id].map((file) =>
                file.fileId === loadedFile.fileId
                  ? {
                    ...file,
                    url: loadedFile.blobUrl,
                    contentType: loadedFile.contentType,
                    orderNumber: loadedFile.orderNumber,
                    fileName: loadedFile.fileName
                  }
                  : file
              );
              updatedFiles.sort((a, b) => a.orderNumber - b.orderNumber);
              const deepCopiedFiles = JSON.parse(JSON.stringify(updatedFiles));
              setInitialFilesForActiveInfoCard(deepCopiedFiles);
              return { ...prev, [activeInfoCard.id]: updatedFiles };
            });
            loadedFilesCount++;
            setFileProgress((loadedFilesCount / (totalFilesCount * 2)) * 100);
          }
        });
      });

      await Promise.allSettled(filePromises);
    } catch (error) {
      console.error("Error processing card click:", error);
      setError("Не вдалося завантажити деталі картки та файлів.");
    }
  };

  const clearFilters = () => {
    setFilters(initialFilters);
    setSearchQuery("");
  };

  const handleInfoCardDelete = async (id) => {
    try {
      setLoading(true);
      await FileService.deleteInfoCard(id);
      await fetchInfoCards(activeUser.id);
    } catch (error) {
      console.error("Помилка при спробі видалити картку:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFavoriteClick = (cardId) => {
    setFavoriteCards((prevFavorites) => {
      if (prevFavorites.includes(cardId)) {
        FileService.updateFavorite(cardId, false);
        return prevFavorites.filter((id) => id !== cardId);
      } else {
        FileService.updateFavorite(cardId, true);
        return [...prevFavorites, cardId];
      }
    });
  };

  const sortedInfoCards = [...filteredInfoCards].sort((a, b) => {
    if (favoriteCards.includes(a.id) && !favoriteCards.includes(b.id)) {
      return -1;
    }
    if (!favoriteCards.includes(a.id) && favoriteCards.includes(b.id)) {
      return 1;
    }
    return 0;
  });

  const fetchImageUrls = async (infoCardId, updatedInfoCard) => {
    const infoCard = updatedInfoCard ? updatedInfoCard : await waitForCard(infoCardId);
    let newImageUrl = defaultPlaceholderImage;
    try {
      if (infoCard.previewFileId) {
        const response = await FileService.viewFile(infoCard.previewFileId);
        const fileModel = response.data;
        const contentType = fileModel.contentType;
        const base64Data = fileModel.data;
        const binaryData = atob(base64Data);
        const bytes = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
          bytes[i] = binaryData.charCodeAt(i);
        }
        const blob = new Blob([bytes], { type: contentType });
        const blobUrl = URL.createObjectURL(blob);

        newImageUrl = contentType.includes("pdf")
          ? pdfPlaceholderImage
          : blobUrl;
      }
      setMainImageUrls((prev) => ({
        ...prev,
        [infoCard.id]: newImageUrl
      }));
    } catch (error) {
      console.error("Помилка при завантаженні зображення:", error);
    }
  };

  const fetchInfoCards = useCallback(async (userId) => {
    setError(null);
    try {
      const fetchedInfoCards = await FileService.getAllInfoCards(userId);
      if (fetchedInfoCards.length === 0) {
        setError("Інформаційні картки не знайдені.");
      } else {
        const sortedInfoCards = fetchedInfoCards.sort((a, b) => {
          if (!a.date && !b.date) return 0;
          if (!a.date) return 1;
          if (!b.date) return -1;
          return new Date(b.date) - new Date(a.date);
        });
        setInfoCards(sortedInfoCards);
        const favoriteCards = fetchedInfoCards
          .filter((card) => card.favorite)
          .map((card) => card.id);
        setFavoriteCards(favoriteCards);
      }
    } catch (error) {
      console.error("Помилка при отриманні інформаційних карток:", error);
      setError("Помилка при отриманні інформаційних карток: " + error);
    }
  }, []);

  useEffect(() => {
    infoCardsRef.current = infoCards;
  }, [infoCards]);

  useEffect(() => {
    if (!isLoading && isLoggedIn) {
      setLoading(true);
      fetchStorageUsage();
      fetchFamilyMembers();
      setLoading(false);
    }
  }, [isLoggedIn, isLoading]);

  useEffect(() => {
    const storedUserId = localStorage.getItem("activeUserId");
    if (storedUserId && users.length > 0) {
      const storedUser = users.find((user) => user.id === parseInt(storedUserId));
      if (storedUser) {
        setActiveUser(storedUser);
      } else {
        const userId = localStorage.getItem("userId");
        const mainUser = users.find((user) => user.id === parseInt(userId));
        setActiveUser(mainUser);
        localStorage.setItem("activeUserId", userId);
      }
    } else if (!storedUserId && users.length > 0) {
      const userId = localStorage.getItem("userId");
      const mainUser = users.find((user) => user.id === parseInt(userId));
      setActiveUser(mainUser);
      localStorage.setItem("activeUserId", userId);
    } else {
      UserService.getUserInfo().then(user => {
        setActiveUser(user);
      });
    }
  }, [users]);

  useEffect(() => {
    setInfoCards([]);
    setFavoriteCards([]);
    if (activeUser) {
      setLoading(true);
      fetchInfoCards(activeUser.id);
      setLoading(false);
    }
  }, [activeUser]);

  useEffect(() => {
    if (inView) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [inView]);

  if (!isLoading && !isLoggedIn) {
    return (
      <MDBContainer className="d-flex justify-content-center align-items-center text-center mt-5">
        <Typography
          variant="h4"
          className="d-flex justify-content-center align-items-center text-center mt-5"
        >
          Для доступу необхідно виконати авторизацію
        </Typography>
      </MDBContainer>
    );
  }

  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <CircularProgress/>
        </div>
      )}
      <Box display="flex" justifyContent="center" mt={1}>
        {users.map((user) => (
          <Button
            key={user.id}
            onClick={() => handleUserSwitch(user)}
            variant={activeUser?.id === user.id ? "contained" : "outlined"}
            startIcon={<Avatar src={user.avatarUrl} alt={user.name} />}
            sx={{
              textTransform: "none",
              margin: "0 16px",
              padding: "0px 0px",
              backgroundColor: activeUser?.id === user.id ? "#9BD1B8" : "transparent",
              color: "black",
              borderColor: activeUser?.id === user.id ? "#9BD1B8" : "gray",
              '&:hover': {
                backgroundColor: activeUser?.id === user.id ? "#87C9A9" : "#f0f0f0",
                borderColor: "#87C9A9",
              },
              width: "170px",
              height: "50px"
            }}
          >
            {user.name}
          </Button>

        ))}
        {!isMobile && (
          <Grid item className={"ms-5 mt-1"}>
            <StorageUsage
              storageUsage={storageUsage}
              storageUsageError={storageUsageError}
            />
          </Grid>
        )}
      </Box>
      <MDBContainer fluid>
        <InfoCardFilters
          filters={filters}
          setFilters={setFilters}
          getUniqueValues={getUniqueValues}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          clearFilters={clearFilters}
          setIsOpen={setShowModal}
          mode={mode}
          setMode={setMode}
          storageUsage={storageUsage}
          storageUsageError={storageUsageError}
          documentTypes={documentTypes}
        />
        {error ? (
          <div
            className="container alert alert-warning text-center mt-3"
            role="alert"
          >
            {error}
          </div>
        ) : (
          <div className="pt-3">
            <Box display="flex" flexWrap="wrap">
              {sortedInfoCards.slice(0, page * itemsPerPage).map((infoCard) => (
                <InfoCard
                  key={infoCard.id}
                  infoCard={infoCard}
                  mainImageUrls={mainImageUrls}
                  defaultPlaceholderImage={defaultPlaceholderImage}
                  handleInfoCardClick={handleInfoCardClick}
                  handleFavoriteClick={handleFavoriteClick}
                  fetchImageUrls={fetchImageUrls}
                  favoriteCards={favoriteCards}
                  isMobile={isMobile}
                />
              ))}
            </Box>
          </div>
        )}
        <div ref={ref}/>
        <InfoCardModal
          isOpen={showModal}
          setIsOpen={setShowModal}
          infoCards={infoCards}
          fileIdsMap={fileIdsMap}
          setFileIdsMap={setFileIdsMap}
          activeInfoCard={activeInfoCard}
          setActiveInfoCard={setActiveInfoCard}
          handleInfoCardDelete={handleInfoCardDelete}
          handleInfoCardClick={handleInfoCardClick}
          fileProgress={fileProgress}
          initialFilesForActiveInfoCard={initialFilesForActiveInfoCard}
          setInitialFilesForActiveInfoCard={setInitialFilesForActiveInfoCard}
          loadAttachedFiles={loadAttachedFiles}
          activeCardFileIds={activeCardFileIds}
          fetchImageUrls={fetchImageUrls}
          fetchInfoCards={fetchInfoCards}
          mode={mode}
          fetchStorageUsage={fetchStorageUsage}
          storageUsage={storageUsage}
          documentTypes={documentTypes}
          activeUser={activeUser}
        />
      </MDBContainer>
    </>
  );
};

export default Medicine;
