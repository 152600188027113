import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import DownloadIcon from '@mui/icons-material/Download'; // Імпортуємо іконку завантаження
import { Box, Button, Card, CardMedia, IconButton, LinearProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useAuth } from "../../context/AuthContext";

const AttachedFiles = ({
                         fileIdsMap,
                         setFileIdsMap,
                         activeInfoCard,
                         setReorderedFiles,
                         handleOpenFileDeleteConfirm,
                         fileProgress,
                         loadAttachedFiles,
                         activeCardFileIds,
                         filesToDelete
                       }) => {
  const { isMobile } = useAuth();
  const [showButton, setShowButton] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const pdfPlaceholderImage = process.env.PUBLIC_URL + "/images/pdf-icon-2.webp";
  const defaultPlaceholderImage = process.env.PUBLIC_URL + "/images/default-placeholder-2.webp";
  const imageWidth = "275px";
  const imageHeight = "388px";

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;

    setFileIdsMap((prevMap) => {
      if (!Array.isArray(prevMap[activeInfoCard.id])) {
        console.error("No files found for the current activeInfoCard");
        return prevMap;
      }

      const files = [...prevMap[activeInfoCard.id]];
      const [movedFile] = files.splice(source.index, 1);
      files.splice(destination.index, 0, movedFile);

      const newFilesOrder = files
        .filter((file) => file && Object.keys(file).length > 0)
        .map((file, index) => ({
          ...file,
          orderNumber: index + 1
        }));

      const updatedFileIdsMap = {
        ...prevMap,
        [activeInfoCard.id]: newFilesOrder
      };

      setFileIdsMap(updatedFileIdsMap);
      setReorderedFiles(newFilesOrder);
      return updatedFileIdsMap;
    });
  };

  const handleClickLoadAllAttachedFiles = () => {
    setShowButton(false);
    loadAttachedFiles(activeInfoCard.id);
  };

  const handleSetPreview = (fileId) => {
    setFileIdsMap((prevMap) => {
      const files = [...prevMap[activeInfoCard.id]];
      const fileIndex = files.findIndex((file) => file.fileId === fileId);
      const [selectedFile] = files.splice(fileIndex, 1);
      files.unshift(selectedFile);
      const newFilesOrder = files.map((file, index) => ({
        ...file,
        orderNumber: index + 1
      }));
      const updatedFileIdsMap = {
        ...prevMap,
        [activeInfoCard.id]: newFilesOrder
      };

      setFileIdsMap(updatedFileIdsMap);
      setReorderedFiles(newFilesOrder);
      return updatedFileIdsMap;
    });
  };

  const attachedFilesExists = () => {
    return (
      fileIdsMap &&
      fileIdsMap[activeInfoCard.id] &&
      fileIdsMap[activeInfoCard.id].length > 0
    );
  };

  useEffect(() => {
    if (fileProgress && fileProgress < 100) {
      setShowProgressBar(true);
    } else {
      setTimeout(() => {
        setShowProgressBar(false);
      }, 500);
    }
  }, [fileProgress]);

  useEffect(() => {
    if (
      activeCardFileIds &&
      (isMobile ? activeCardFileIds.length > 1 : activeCardFileIds.length > 4) &&
      fileIdsMap &&
      fileIdsMap[activeInfoCard.id] &&
      (isMobile ? fileIdsMap[activeInfoCard.id].length < 2 : fileIdsMap[activeInfoCard.id].length < 5) &&
      filesToDelete.length === 0
    ) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [activeCardFileIds, fileIdsMap]);

  return (
    <>
      <Typography
        variant="h6"
        className="d-flex justify-content-center align-items-center text-center mt-2 mb-2"
      >
        {attachedFilesExists()
          ? "Прикріплені файли"
          : "Прикріплені файли відсутні"}
      </Typography>
      {showProgressBar && (
        <LinearProgress
          variant="determinate"
          value={fileProgress}
          style={{ width: "100%", marginBottom: "5px" }}
        />
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="droppableFiles"
          direction={isMobile ? "vertical" : "horizontal"}
        >
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                display: isMobile ? "" : "flex",
                overflowX: isMobile ? "clip" : "auto",
                overflowY: isMobile ? "auto" : "clip",
                minHeight: isMobile ? "100%" : "300px",
                padding: isMobile ? "0 2px" : "0 8px"
              }}
            >
              {Object.entries(fileIdsMap?.[activeInfoCard?.id] ?? {}).map(
                ([fileId, file], index) => (
                  <Draggable key={fileId} draggableId={fileId} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          marginRight: isMobile ? "" : "20px",
                          marginBottom: "20px"
                        }}
                      >
                        <Card
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            height: imageHeight,
                            width: "100%",
                            userSelect: "none",
                            position: "relative"
                          }}
                        >
                          <Box
                            style={{
                              width: imageWidth,
                              height: imageHeight,
                              overflow: "auto",
                              padding: "4px",
                              boxSizing: "border-box"
                            }}
                          >
                            <CardMedia
                              component="img"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                objectPosition: "left top",
                                borderRadius: "5px"
                              }}
                              image={
                                file &&
                                file.contentType &&
                                file.contentType.includes("image")
                                  ? file.url || defaultPlaceholderImage
                                  : pdfPlaceholderImage
                              }
                              alt="Прикріплене зображення"
                            />
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            padding={1}
                          >
                            <IconButton
                              onClick={() => handleSetPreview(file.fileId)}
                              disabled={index === 0}
                            >
                              {index === 0 ? (
                                <StarIcon color="primary"/>
                              ) : (
                                <StarBorderIcon/>
                              )}
                            </IconButton>
                            <IconButton
                              onClick={() => window.open(file.url, "_blank")}
                              className={isMobile ? "mb-3" : ""}
                            >
                              <VisibilityIcon/>
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                const link = document.createElement('a');
                                link.href = file.url;
                                link.download = file.fileName || 'file';
                                link.click();
                              }}
                            >
                              <DownloadIcon/>
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                handleOpenFileDeleteConfirm(fileId)
                              }
                            >
                              <DeleteIcon/>
                            </IconButton>
                          </Box>
                        </Card>
                      </div>
                    )}
                  </Draggable>
                )
              )}
              {provided.placeholder}
              {showButton && (
                <Button
                  variant="outlined"
                  color="success"
                  type="button"
                  style={{
                    width: isMobile ? "100%" : imageWidth,
                    height: isMobile ? "auto" : imageHeight
                  }}
                  onClick={handleClickLoadAllAttachedFiles}
                >
                  Відобразити всі файли
                </Button>
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default AttachedFiles;
