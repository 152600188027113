import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import UserService from "../api/user/UserService";
import userService from "../api/user/UserService";
import dayjs from "dayjs";
import FileService from "../api/files/FileService";
import { useAuth } from "../context/AuthContext";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { MDBBtn } from "mdb-react-ui-kit";
import confetti from "canvas-confetti";
import CustomSnackbar from "../components/common/CustomSnackbar";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const formatDateTime = (dateString) => {
  return dayjs(dateString).format('YYYY-MM-DD HH:mm:ss');
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UserProfile = () => {
  const MAX_TOTAL_STORAGE_MB = process.env.REACT_APP_USER_STORAGE_LIMIT;
  const MIN_PASSWORD_LENGTH = 8;
  const DELETE_ACCOUNT_CONFIRMATION = "Видалити";
  const { isLoggedIn, isLoading, isMobile, userLogout } = useAuth();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [storageUsage, setStorageUsage] = useState(null);
  const [storageUsageError, setStorageUsageError] = useState(null);

  const [newName, setNewName] = useState("");
  const [openChangeNameDialog, setOpenChangeNameDialog] = useState(false);
  const [nameError, setNameError] = useState(false);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState(null);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  const [confirmationText, setConfirmationText] = useState("");
  const [confirmationError, setConfirmationError] = useState(null);

  const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState(false);
  const [openChangeSubscriptionDialog, setOpenChangeSubscriptionDialog] = useState(false);
  const [openDeleteAccountDialog, setOpenDeleteAccountDialog] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [snackbarSeverity, setSnackbarSeverity] = useState(null);

  const [avatarFile, setAvatarFile] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const fileInputRef = useRef(null);

  const handleNameSave = async () => {
    try {
      if (!newName || newName.trim() === "") {
        setNameError(true);
        return;
      }
      setNameError(false);
      await UserService.updateUserName(newName);
      await fetchUserInfo();
      showSnackbar("Ім'я успішно змінено!", "success");
      setOpenChangeNameDialog(false);
    } catch (error) {
      console.error("Failed to update name:", error);
      showSnackbar("Не вдалося змінити ім'я: " + error.message, "error");
    }
  };

  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setAvatarFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatarUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAvatarUpload = async () => {
    if (avatarFile) {
      try {
        await UserService.uploadAvatar(avatarFile);
        showSnackbar("Нову аватарку успішно збережено!", "success");
      } catch (error) {
        console.error("Не вдалося завантажити аватарку", error);
        showSnackbar("Не вдалося завантажити аватарку: " + error.message, "error");
      } finally {
        setAvatarFile(null);
      }
    }
  };

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleSubscriptionChange = (event) => {
    setSelectedSubscription(event.target.value);
  };

  const handleSubscriptionSave = async () => {
    try {
      await userService.updateUserSubscription(selectedSubscription);
      await fetchUserInfo();
      confetti({
        particleCount: 300,
        spread: 210,
        origin: { y: 0.6 }
      });
      setOpenChangeSubscriptionDialog(false);
      showSnackbar("Вітаємо! Вашу підписку успішно оновлено!", "success");
    } catch (error) {
      console.error("Failed to update subscription:", error);
      setError(error.message);
    } finally {
      setSelectedSubscription(null);
    }
  };

  const handlePasswordChange = async () => {
    if (newPassword.length < MIN_PASSWORD_LENGTH) {
      setPasswordError(`Пароль має містити не менше ${MIN_PASSWORD_LENGTH} символів`);
      return;
    }
    if (newPassword !== confirmPassword) {
      setPasswordError("Паролі не співпадають");
      return;
    }
    try {
      await UserService.changePassword(currentPassword, newPassword);
      setPasswordError(null);
      showSnackbar("Вітаємо! Ваш пароль успішно оновлено!", "success");
      handlePasswordChangeDialogClose();
      setTimeout(() => userLogout(), 1500);
    } catch (err) {
      setPasswordError("Не вдалося змінити пароль: " + err.message);
      showSnackbar("Не вдалося змінити пароль: " + err.message, "error");
    }
  };

  const handlePasswordChangeDialogClose = () => {
    setOpenChangePasswordDialog(false);
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setPasswordError(null);
  }

  const handleDeleteAccount = async () => {
    if (confirmationText !== DELETE_ACCOUNT_CONFIRMATION) {
      setConfirmationError("Будь ласка, введіть точний текст для підтвердження видалення акаунту.");
      return;
    }
    try {
      await UserService.deleteAccount();
      handleDeleteAccountDialogClose();
      setTimeout(() => userLogout(), 1500);
      showSnackbar("Ваш обліковий запис успішно видалено.", "success");
    } catch (err) {
      setConfirmationError("Не вдалося видалити обліковий запис: " + err.message);
      showSnackbar("Не вдалося видалити обліковий запис: " + err.message, "error");
    }
  };

  const handleDeleteAccountDialogClose = () => {
    setOpenDeleteAccountDialog(false);
    setConfirmationText("");
    setConfirmationError(null);
  }

  const handleChangeNameCloseDialog = () => {
    setOpenChangeNameDialog(false);
    setNameError(false);
  }

  const fetchStorageUsage = async () => {
    try {
      const usage = await FileService.getUserStorageUsage();
      setStorageUsage(usage);
    } catch (err) {
      setStorageUsageError(err.message);
    }
  };

  const fetchUserInfo = async () => {
    try {
      const userInfo = await UserService.getUserInfo();
      setUser(userInfo);
      if (userInfo.avatar) {
        const binaryData = atob(userInfo.avatar);
        const bytes = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
          bytes[i] = binaryData.charCodeAt(i);
        }
        const blob = new Blob([bytes], { type: 'image/jpeg' });
        const avatarUrl = URL.createObjectURL(blob);
        setAvatarUrl(avatarUrl);
      }
    } catch (error) {
      setError("Failed to load user data.");
    } finally {
      setLoading(false);
    }
  };

  const fetchSubscriptions = async () => {
    try {
      const subscriptionsData = await UserService.getSubscriptions();
      setSubscriptions(subscriptionsData);
    } catch (error) {
      setError("Failed to load subscriptions.");
    }
  };

  useEffect(() => {
    if (!isLoading && isLoggedIn) {
      fetchStorageUsage();
      fetchSubscriptions();
    }
  }, [isLoading, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchUserInfo();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (openChangeSubscriptionDialog && user) {
      console.log("Selected subscription ID:", user.subscriptionId); // Логування для перевірки
      setSelectedSubscription(user.subscriptionId);
    }
  }, [openChangeSubscriptionDialog, user]);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>{error}</Typography>;
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      mt: 5,
      ml: isMobile ? 1 : 0,
      mr: isMobile ? 1 : 0
    }}>
      <Typography variant="h5">Мій профіль</Typography>
      <Box sx={{ mt: 3, mb: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <IconButton
              onClick={handleAvatarClick}
              sx={{
                backgroundColor: 'white',
                boxShadow: 1,
                '&:hover': {
                  backgroundColor: 'lightgray'
                }
              }}
            >
              <EditIcon fontSize="small"/>
            </IconButton>
          }
        >
          <Avatar
            sx={{ width: 120, height: 120 }}
            src={avatarUrl}
          />
        </Badge>
        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleAvatarChange}
        />
      </Box>
      <TableContainer component={Paper} sx={{ maxWidth: 600 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Ім'я</TableCell>
              <TableCell>{user.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Підписка</TableCell>
              <TableCell>{subscriptions.find(sub => sub.id === user.subscriptionId)?.title || "Невідома підписка"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Всього доступно місця</TableCell>
              <TableCell>{MAX_TOTAL_STORAGE_MB} MB</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Використано місця</TableCell>
              <TableCell>{storageUsageError ? storageUsageError : storageUsage + ' MB'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>{user.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>{user.id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Створений</TableCell>
              <TableCell>{formatDateTime(user.created)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {avatarFile && (
          <Button sx={{ marginTop: 1.5 }} variant="contained" color="success" fullWidth onClick={handleAvatarUpload}>
            Зберегти новий аватар
          </Button>
        )}
        <Button
          sx={{ marginTop: 1.5 }}
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => {
            setNewName(user?.name || "");
            setOpenChangeNameDialog(true);
          }}
        >
          Змінити ім'я
        </Button>
        <Button sx={{ marginTop: 1.5 }} variant="contained" color="primary" fullWidth
                onClick={() => setOpenChangeSubscriptionDialog(true)}>
          Змінити підписку
        </Button>
        <Button sx={{ marginTop: 1.5 }} variant="contained" color="primary" fullWidth
                onClick={() => setOpenChangePasswordDialog(true)}>
          Змінити пароль
        </Button>
        <Button sx={{ marginTop: 1.5, marginBottom: 1.5 }} variant="contained" color="error" fullWidth
                onClick={() => setOpenDeleteAccountDialog(true)}>
          Видалити аккаунт
        </Button>
      </TableContainer>

      {/* Діалог для зміни імені */}
      <Dialog
        open={openChangeNameDialog}
        onClose={handleChangeNameCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Змінити ім'я</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleChangeNameCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <TextField
            label="Нове ім'я"
            required={true}
            fullWidth
            margin="dense"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            error={nameError}
            helperText={nameError ? "Ім'я не може бути порожнім" : ""}
          />
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <MDBBtn
                block
                color="success"
                onClick={handleNameSave}
              >
                Зберегти
              </MDBBtn>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDBBtn
                block
                color="secondary"
                onClick={handleChangeNameCloseDialog}
              >
                Скасувати
              </MDBBtn>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      {/* Попап для зміни паролю */}
      <Dialog
        open={openChangePasswordDialog}
        onClose={handlePasswordChangeDialogClose}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Зміна пароля</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handlePasswordChangeDialogClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon fontSize="large"/>
        </IconButton>
        <DialogContent>
          <Box sx={{ position: "relative" }}>
            <TextField
              label="Поточний пароль"
              type={showCurrentPassword ? "text" : "password"}
              fullWidth
              size="small"
              margin="dense"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <IconButton
              size="small"
              onClick={() => setShowCurrentPassword(!showCurrentPassword)}
              sx={{
                position: "absolute",
                top: "50%",
                right: 8,
                transform: "translateY(-50%)",
                cursor: "pointer"
              }}
            >
              {showCurrentPassword ? <VisibilityOff fontSize="small"/> : <Visibility fontSize="small"/>}
            </IconButton>
          </Box>
          <Box sx={{ position: "relative" }}>
            <TextField
              label="Новий пароль"
              type={showNewPassword ? "text" : "password"}
              fullWidth
              size="small"
              margin="dense"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <IconButton
              size="small"
              onClick={() => setShowNewPassword(!showNewPassword)}
              sx={{
                position: "absolute",
                top: "50%",
                right: 8,
                transform: "translateY(-50%)",
                cursor: "pointer"
              }}
            >
              {showNewPassword ? <VisibilityOff fontSize="small"/> : <Visibility fontSize="small"/>}
            </IconButton>
          </Box>
          <Box sx={{ position: "relative" }}>
            <TextField
              label="Підтвердження паролю"
              type={showConfirmPassword ? "text" : "password"}
              fullWidth
              size="small"
              margin="dense"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="mb-3"
            />
            <IconButton
              size="small"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              sx={{
                position: "absolute",
                top: "50%",
                right: 8,
                transform: "translateY(-50%)",
                cursor: "pointer"
              }}
            >
              {showConfirmPassword ? <VisibilityOff fontSize="small"/> : <Visibility fontSize="small"/>}
            </IconButton>
          </Box>
          {passwordError && <Alert severity="error">{passwordError}</Alert>}
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <MDBBtn
                block
                color="success"
                onClick={() => handlePasswordChange()}
              >
                Зберегти
              </MDBBtn>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDBBtn
                block
                color="secondary"
                onClick={handlePasswordChangeDialogClose}
              >
                Скасувати
              </MDBBtn>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      {/* Попап для зміни підписки */}
      <Dialog
        open={openChangeSubscriptionDialog}
        onClose={() => setOpenChangeSubscriptionDialog(false)}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Оберіть підписку</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenChangeSubscriptionDialog(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon fontSize="large"/>
        </IconButton>
        <DialogContent>
          {error && (
            <div className="alert alert-danger text-center" role="alert">
              {error}
            </div>
          )}
          <RadioGroup value={selectedSubscription} onChange={handleSubscriptionChange}>
            <Grid container spacing={2} sx={{ mt: 2 }} justifyContent="center">
              {subscriptions.map((subscription) => (
                <Grid item xs={12} sm={6} md={3} key={subscription.id}>
                  <Card variant="outlined" sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                  }}>
                    <CardMedia
                      component="img"
                      height="140"
                      image={"https://picsum.photos/200/140?random=" + subscription.id}
                      alt={subscription.title}
                      sx={{ objectFit: 'cover' }}
                    />
                    <CardContent>
                      <Typography variant="h6" component="div" className="text-center">
                        {subscription.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" className="text-center mt-3">
                        {subscription.description}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <FormControlLabel
                        value={subscription.id}
                        control={<Radio/>}
                        label=""
                        sx={{ width: '100%', m: 0, justifyContent: 'center' }}
                      />
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </RadioGroup>
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <MDBBtn
                block
                color="success"
                onClick={handleSubscriptionSave}
              >
                Зберегти
              </MDBBtn>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDBBtn
                block
                color="secondary"
                onClick={() => setOpenChangeSubscriptionDialog(false)}
              >
                Скасувати
              </MDBBtn>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      {/* Попап для видалення аккаунта */}
      <Dialog
        open={openDeleteAccountDialog}
        onClose={handleDeleteAccountDialogClose}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Підтвердіть дію</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleDeleteAccountDialogClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon fontSize="large"/>
        </IconButton>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="text-center"
          >
            Ви впевнені, що хочете видалити обліковий запис?
            <br/>
            Ця дія не може бути скасована.
          </DialogContentText>
          <TextField
            label={`Введіть '${DELETE_ACCOUNT_CONFIRMATION}' для підтвердження`}
            fullWidth
            margin="dense"
            value={confirmationText}
            onChange={(e) => setConfirmationText(e.target.value)}
            error={!!confirmationError}
            helperText={confirmationError}
          />
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <MDBBtn
                block
                color="danger"
                onClick={handleDeleteAccount}
              >
                Видалити
              </MDBBtn>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDBBtn
                block
                color="secondary"
                onClick={handleDeleteAccountDialogClose}
              >
                Скасувати
              </MDBBtn>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <CustomSnackbar
        message={snackbarMessage}
        severity={snackbarSeverity}
        open={openSnackbar}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default UserProfile;
