import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./components/common/Layout";
import Login from "./components/auth/Login";
import Medicine from "./pages/Medicine";
import { AuthProvider } from "./context/AuthContext";
import Homepage from "./pages/Homepage";
import MedicineAnalytics from "./pages/MedicineAnalytics";
import UserProfile from "./pages/UserProfile";
import AdminPanel from "./pages/AdminPanel";
import FamilyPage from "./pages/MyFamilyPage";

export default function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Layout/>}>
            <Route index element={<Homepage/>}/>
            <Route path="login" element={<Login/>}/>
            <Route path="medicine" element={<Medicine/>}/>
            <Route path="medicine/analytics" element={<MedicineAnalytics/>}/>
            <Route path="user/profile" element={<UserProfile/>}/>
            <Route path="user/my-family" element={<FamilyPage/>}/>
            <Route path="/admin" element={<AdminPanel/>}/>
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
}
