import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import { Box, Card, CardMedia, IconButton } from "@mui/material";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { MDBBadge } from "mdb-react-ui-kit";

const badgeStyle = {
  textAlign: "center",
  width: "130px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  marginTop: "10px"
};

const InfoCard = ({
                    infoCard,
                    mainImageUrls,
                    defaultPlaceholderImage,
                    handleInfoCardClick,
                    handleFavoriteClick,
                    fetchImageUrls,
                    favoriteCards,
                    isMobile
                  }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1
  });
  const [downloadStatusMap, setDownloadStatusMap] = useState({});
  const imageWidth = "275px";
  const imageHeight = "388px";

  useEffect(() => {
    if (inView && !mainImageUrls[infoCard.id] && !downloadStatusMap[infoCard.id]?.downloadStarted) {
      setDownloadStatusMap(prevState => ({
        ...prevState,
        [infoCard.id]: { downloadStarted: true }
      }));
      fetchImageUrls(infoCard.id);
    }
  }, [inView, infoCard.id, fetchImageUrls, downloadStatusMap]);

  return (
    <Box
      key={infoCard.id}
      width={isMobile ? "100%" : "25%"}
      padding="15px"
      boxSizing="border-box"
    >
      <Card
        ref={ref}
        onClick={() => handleInfoCardClick(infoCard.id, true, false)}
        style={{
          cursor: "pointer",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          height: imageHeight,
          width: "100%",
          userSelect: "none",
          position: "relative"
        }}
      >
        <Box
          style={{
            width: imageWidth,
            height: imageHeight,
            overflow: "auto",
            padding: "4px",
            boxSizing: "border-box"
          }}
        >
          <CardMedia
            component="img"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "left top",
              borderRadius: "5px"
            }}
            image={
              mainImageUrls[infoCard.id] || defaultPlaceholderImage
            }
            alt={infoCard.cardName}
          />
        </Box>
        <IconButton
          size="large"
          onClick={(e) => {
            e.stopPropagation();
            handleFavoriteClick(infoCard.id);
          }}
          style={{
            position: "absolute",
            top: "10px",
            right: "55px"
          }}
        >
          {favoriteCards.includes(infoCard.id) ? (
            <FavoriteIcon color="warning" fontSize="large"/>
          ) : (
            <FavoriteBorderIcon color="warning" fontSize="large"/>
          )}
        </IconButton>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          flexGrow={1}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
          >
            <MDBBadge pill light style={badgeStyle}>
              {infoCard.serviceName}
            </MDBBadge>
            <MDBBadge pill light style={badgeStyle}>
              {infoCard.organization}
            </MDBBadge>
            <MDBBadge pill light style={badgeStyle}>
              {infoCard.specialization}
            </MDBBadge>
            <MDBBadge pill light style={badgeStyle}>
              {infoCard.doctor}
            </MDBBadge>
            <MDBBadge pill light style={badgeStyle}>
              {infoCard.date && new Date(infoCard.date).toLocaleDateString()}
            </MDBBadge>
            {infoCard.price != 0 && (
              <MDBBadge pill light style={badgeStyle}>
                {`${Number(infoCard.price).toFixed(2)} грн`}
              </MDBBadge>
            )}
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default InfoCard;
