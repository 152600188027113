import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

export default function Layout() {
  return (
    <div className="layout">
      <header>
        <Header></Header>
      </header>

      <main className="content">
        <Outlet/>
      </main>

      <footer>
        <Footer></Footer>
      </footer>
    </div>
  );
}
