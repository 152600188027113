import { MDBBtn, MDBNavbarItem } from "mdb-react-ui-kit";
import React, { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import LoginModal from "./LoginModal";
import SignUpModal from "./SignUpModal";
import AccountMenu from "./AccountMenu";

const Login = ({ setShowBasic }) => {
  const { isLoggedIn, isMobile } = useAuth();
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);
  const [isOpenSignUpModal, setIsOpenSignUpModal] = useState(false);

  const openSignUpModal = () => {
    setIsOpenLoginModal(false);
    setIsOpenSignUpModal(true);
  };

  return (
    <div>
      {isLoggedIn ? (
        <AccountMenu setShowBasic={setShowBasic}/>
      ) : (
        <>
          <MDBNavbarItem className={isMobile ? "" : "me-5"}>
            <MDBBtn color="success" onClick={() => setIsOpenLoginModal(true)}>
              Увійти
            </MDBBtn>
          </MDBNavbarItem>
          <LoginModal
            isOpenLoginModal={isOpenLoginModal}
            setIsOpenLoginModal={setIsOpenLoginModal}
            openSignUpModal={openSignUpModal}
            setShowBasic={setShowBasic}
          />
          <SignUpModal
            isOpenSignUpModal={isOpenSignUpModal}
            setIsOpenSignUpModal={setIsOpenSignUpModal}
          />
        </>
      )}
    </div>
  );
};

export default Login;
