import { MDBContainer, MDBFooter } from "mdb-react-ui-kit";
import React from "react";

export default function Footer() {
  return (
    <MDBFooter className="d-flex justify-content-between align-items-center text-center p-2">
      <MDBContainer className="text-center">2024</MDBContainer>
    </MDBFooter>
  );
}
