import React, { useState } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { Bar } from "react-chartjs-2";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import weekOfYear from "dayjs/plugin/weekOfYear";

dayjs.extend(isoWeek);
dayjs.extend(weekOfYear);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const SpendingDashboard = ({ filteredInfoCards }) => {
  const [groupBy, setGroupBy] = useState("day");

  const groupData = () => {
    const groupedData = {};

    filteredInfoCards.forEach(card => {
      let key;
      switch (groupBy) {
        case "day":
          key = dayjs(card.date).format("YYYY-MM-DD");
          break;
        case "week":
          key = `${dayjs(card.date).isoWeek()} тиждень ${dayjs(card.date).format("YYYY")}`;
          break;
        case "month":
          key = dayjs(card.date).format("YYYY-MM");
          break;
        case "year":
          key = dayjs(card.date).format("YYYY");
          break;
        default:
          key = card.date;
      }

      if (!groupedData[key]) {
        groupedData[key] = 0;
      }

      groupedData[key] += parseFloat(card.price) || 0;
    });

    return groupedData;
  };

  const groupedData = groupData();

  const chartData = {
    labels: Object.keys(groupedData),
    datasets: [
      {
        label: "Витрачені кошти",
        data: Object.values(groupedData),
        backgroundColor: "rgb(75, 192, 192)"
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'category',
        title: {
          display: true,
          text: 'Період'
        }
      },
      y: {
        title: {
          display: true,
          text: 'Сума витрат'
        }
      }
    }
  };

  return (
    <Box sx={{ marginTop: 3, width: '100%', height: '300px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ marginBottom: 1 }}>
          Аналіз витрат
        </Typography>
        <FormControl variant="outlined" size="small" sx={{ marginLeft: 3, marginBottom: 1, minWidth: 120 }}>
          <InputLabel>Групувати за</InputLabel>
          <Select
            value={groupBy}
            label="Групувати за"
            onChange={(e) => setGroupBy(e.target.value)}
          >
            <MenuItem value="day">Днями</MenuItem>
            <MenuItem value="week">Тижнями</MenuItem>
            <MenuItem value="month">Місяцями</MenuItem>
            <MenuItem value="year">Роками</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ position: 'relative', height: '100%' }}>
        <Bar data={chartData} options={options}/>
      </Box>
    </Box>
  );
};

export default SpendingDashboard;
