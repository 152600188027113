import { Button, CircularProgress, IconButton } from "@mui/material";
import {
  MDBBtn,
  MDBCheckbox,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import AuthService from "../../api/auth/AuthService";
import { useAuth } from "../../context/AuthContext";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

const LoginModal = ({
                      isOpenLoginModal,
                      setIsOpenLoginModal,
                      openSignUpModal,
                      setShowBasic
                    }) => {
  const { userLogin, isLoggedIn } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    try {
      const response = await AuthService.authenticate(email, password);
      if (response.data) {
        userLogin(response.data);
        closeLoginModal();
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError("Користувача не знайдено. Перевірте логін та пароль.");
      } else {
        setError(error.message || "Сталася несподівана помилка");
      }
    } finally {
      setLoading(false);
    }
  };

  const closeLoginModal = () => {
    setEmail("");
    setPassword("");
    setError(null);
    setIsOpenLoginModal(false);
    setShowBasic(false);
    setShowPassword(false);
  };

  useEffect(() => {
    if (isLoggedIn) {
      closeLoginModal();
    }
  }, [isLoggedIn]);

  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <CircularProgress/>
        </div>
      )}
      <MDBModal open={isOpenLoginModal} tabIndex="-1" staticBackdrop>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader className="d-flex justify-content-center">
              <MDBModalTitle>Авторизація</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={closeLoginModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBContainer>
                {error && (
                  <div className="alert alert-danger text-center" role="alert">
                    {error}
                  </div>
                )}
                <div className="alert alert-warning text-center" role="alert">
                  Проект знаходиться в режимі закритого бета тестування
                </div>
                <form onSubmit={handleLogin}>
                  <MDBInput
                    className="mb-3"
                    type="email"
                    value={email}
                    id="email"
                    label="Email"
                    required
                    name="username"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className="mb-3 position-relative">
                    <MDBInput
                      type={showPassword ? "text" : "password"}
                      value={password}
                      id="password"
                      label="Пароль"
                      required
                      name="password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <IconButton
                      size="small"
                      onClick={() => setShowPassword(!showPassword)}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        right: 8,
                        transform: "translateY(-50%)",
                        cursor: "pointer"
                      }}
                    >
                      {showPassword ? <VisibilityOff fontSize="small"/> : <Visibility fontSize="small"/>}
                    </IconButton>
                  </div>
                  <MDBRow className="mb-3">
                    <MDBCol>
                      <MDBCheckbox id="remember" label="Запам'ятати мене"/>
                    </MDBCol>
                    <MDBCol className="d-flex justify-content-end align-items-center">
                      <Button
                        type="button"
                        className="btn btn-link p-0 m-0 align-baseline"
                        onClick={() => {/* Додайте функціонал для забутого пароля */
                        }}
                        style={{ textTransform: 'none' }}
                      >
                        Забули пароль?
                      </Button>
                    </MDBCol>
                  </MDBRow>
                  <MDBBtn type="submit" className="mb-3 submit-button" block>
                    Увійти
                  </MDBBtn>
                  <div className="text-center">
                    <p>
                      Ще немає аккаунту?{" "}
                      <Button
                        type="button"
                        className="btn btn-link p-0 m-0 align-baseline ms-1"
                        onClick={openSignUpModal}
                        style={{ textTransform: 'none' }}
                      >
                        Реєстрація
                      </Button>
                    </p>
                  </div>
                </form>
              </MDBContainer>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default LoginModal;
