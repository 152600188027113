import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";

const LinkedCards = ({ infoCards, setLinkCardModalOpen, linkedCards, handleCancel, handleInfoCardClick, documentTypes }) => {

  const openCard = (infoCardId) => {
    handleCancel();
    handleInfoCardClick(
      infoCardId,
      true,
      false,
      true
    );
  }

  return (
    <div>
      {linkedCards.length > 0 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="d-flex justify-content-center align-items-center text-center mt-2 mb-2">
              <Typography variant="h6" className="table-title">
                Пов'язані картки
              </Typography>
              <Button variant="outlined"
                      color="primary"
                      startIcon={<EditIcon/>}
                      className="ms-3"
                      size="small"
                      onClick={() => setLinkCardModalOpen(true)}
                      aria-label="Редагувати">
                Редагувати
              </Button>
            </div>
            <TableContainer component={Paper}>
              <Table aria-label="Пов'язані картки">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Назва картки</TableCell>
                    <TableCell align="center">Тип документу</TableCell>
                    <TableCell align="center">Назва послуги</TableCell>
                    <TableCell align="center">Клініка (організація)</TableCell>
                    <TableCell align="center">Спеціалізація лікаря</TableCell>
                    <TableCell align="center">ПІБ лікаря</TableCell>
                    <TableCell align="center">Дата</TableCell>
                    <TableCell align="center">Ціна</TableCell>
                    <TableCell align="center">Коментар</TableCell>
                    <TableCell align="center">Дія</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {linkedCards.map((linkedCardId) => {
                    const linkedCard = infoCards.find(card => card.id === linkedCardId);
                    return (
                      <TableRow key={linkedCardId}>
                        {linkedCard ? (
                          <>
                            <TableCell className="table-cell-name" align="center">{linkedCard.cardName}</TableCell>
                            <TableCell className="table-cell" align="center">
                              {documentTypes[linkedCard.documentType] || "Невідомий тип"}
                            </TableCell>
                            <TableCell className="table-cell" align="center">{linkedCard.serviceName}</TableCell>
                            <TableCell className="table-cell" align="center">{linkedCard.organization}</TableCell>
                            <TableCell className="table-cell" align="center">{linkedCard.specialization}</TableCell>
                            <TableCell className="table-cell" align="center">{linkedCard.doctor}</TableCell>
                            <TableCell className="table-cell" align="center">{linkedCard.date}</TableCell>
                            <TableCell className="table-cell" align="center">{linkedCard.price}</TableCell>
                            <TableCell className="table-cell" align="center">{linkedCard.comment}</TableCell>
                            <TableCell className="table-cell" align="center">
                              <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                onClick={() => openCard(linkedCardId)}
                                aria-label="Відкрити картку"
                              >
                                Відкрити
                              </Button>
                            </TableCell>
                          </>
                        ) : (
                          <TableCell align="left" colSpan={10}>
                            Картка {linkedCardId} не знайдена
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default LinkedCards;
