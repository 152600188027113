import { Box, Collapse, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import dayjs from "dayjs";
import { MDBBtn, MDBInput } from "mdb-react-ui-kit";
import React, { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import StorageUsage from "../files/StorageUsage";

const filterLabels = {
  organization: "Клініка (Організація)",
  specialization: "Спеціалізація лікаря",
  doctor: "ПІБ лікаря"
};

const periodOptions = {
  lastMonth: "Останній місяць",
  lastQuarter: "Останній квартал",
  lastSixMonths: "Останні півроку",
  lastYear: "Останній рік"
};

const InfoCardFilters = ({
                           filters,
                           setFilters,
                           getUniqueValues,
                           searchQuery,
                           setSearchQuery,
                           clearFilters,
                           setIsOpen,
                           mode,
                           setMode,
                           documentTypes
                         }) => {
  const { isMobile } = useAuth();
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [selectedDocumentType, setDocumentType] = useState(
    filters.documentType || ""
  );

  const toggleFiltersVisibility = () => setFiltersVisible(!filtersVisible);

  const toggleAddModal = () => {
    setMode("add");
    setIsOpen(true);
  };

  const clearAllFilters = () => {
    setSearchQuery("");
    setDocumentType("");
    setSelectedPeriod("");
    clearFilters();
  };

  const handleFilterChange = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  const handleDocumentTypeChange = (event) => {
    const value = event.target.value;
    setDocumentType(value);
    setFilters({ ...filters, documentType: value });
  };

  const handlePeriodChange = (event) => {
    const value = event.target.value;
    const now = dayjs();
    let startDate,
      endDate = now.format("YYYY-MM-DD");

    switch (value) {
      case "lastMonth":
        startDate = now.subtract(1, "month").format("YYYY-MM-DD");
        break;
      case "lastQuarter":
        startDate = now.subtract(3, "months").format("YYYY-MM-DD");
        break;
      case "lastSixMonths":
        startDate = now.subtract(6, "months").format("YYYY-MM-DD");
        break;
      case "lastYear":
        startDate = now.subtract(1, "year").format("YYYY-MM-DD");
        break;
      default:
        startDate = "";
        endDate = "";
    }

    setSelectedPeriod(value);
    setFilters({ ...filters, startDate, endDate });
  };

  const getOptionsForFilter = (filterKey) =>
    getUniqueValues(filterKey, filters);

  const areFiltersActive = () => {
    return (
      searchQuery !== "" ||
      selectedDocumentType !== "" ||
      selectedPeriod !== "" ||
      Object.values(filters).some((value) => value !== "")
    );
  };

  return (
    <div className="ms-3 me-3">
      <Box marginTop={2}>
        <Grid container spacing={2} justifyContent="center">
          {!isMobile && (
            <Grid item xs={12} sm={2} className={isMobile ? "" : "ms-5"}>
              <MDBInput
                label="Швидкий пошук"
                type="text"
                style={{ height: "40px" }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Grid>
          )}
          {!isMobile && (
            <Grid item xs={12} sm={2}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel>Тип картки</InputLabel>
                <Select
                  value={selectedDocumentType}
                  onChange={handleDocumentTypeChange}
                  label="Тип картки"
                >
                  <MenuItem value="">Всі</MenuItem>
                  {Object.entries(documentTypes).map(([value, label]) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {!isMobile && (
            <Grid item xs={12} sm={2}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel>Період</InputLabel>
                <Select
                  value={selectedPeriod}
                  onChange={handlePeriodChange}
                  label="Період"
                >
                  <MenuItem value="">Всі</MenuItem>
                  {Object.entries(periodOptions).map(([value, label]) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={isMobile ? 12 : false} sm={isMobile ? 2 : false}>
            <MDBBtn outline block onClick={toggleFiltersVisibility}>
              {filtersVisible ? "Сховати фільтри" : "Налаштувати фільтри"}
            </MDBBtn>
          </Grid>
          {!isMobile && (
            <Grid item>
              <MDBBtn
                color="primary"
                block
                onClick={clearAllFilters}
                disabled={!areFiltersActive()}
              >
                Очистити всі фільтри
              </MDBBtn>
            </Grid>
          )}
          {isMobile && areFiltersActive() && (
            <Grid item xs={12} sm={2}>
              <MDBBtn color="primary" block onClick={clearAllFilters}>
                Очистити всі фільтри
              </MDBBtn>
            </Grid>
          )}
          {mode !== "analytics" && (
            <Grid item xs={isMobile ? 12 : false} sm={isMobile ? 3 : false}>
              <MDBBtn color="success" block onClick={toggleAddModal}>
                Створити
              </MDBBtn>
            </Grid>
          )}
        </Grid>
        <Collapse in={filtersVisible}>
          <Grid container spacing={2} justifyContent="center" className="mt-1">
            {isMobile && (
              <Grid item xs={12} sm={2}>
                <MDBInput
                  label="Швидкий пошук"
                  type="text"
                  style={{ height: "40px" }}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </Grid>
            )}
            {isMobile && (
              <Grid item xs={12} sm={2}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel>Тип картки</InputLabel>
                  <Select
                    value={selectedDocumentType}
                    onChange={handleDocumentTypeChange}
                    label="Тип картки"
                  >
                    <MenuItem value="">Всі</MenuItem>
                    {Object.entries(documentTypes).map(([value, label]) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {isMobile && (
              <Grid item xs={12} sm={2}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel>Період</InputLabel>
                  <Select
                    value={selectedPeriod}
                    onChange={handlePeriodChange}
                    label="Період"
                  >
                    <MenuItem value="">Всі</MenuItem>
                    {Object.entries(periodOptions).map(([value, label]) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {Object.keys(filterLabels).map((key) => (
              <Grid key={key} item xs={12} sm={2.55}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel>{filterLabels[key]}</InputLabel>
                  <Select
                    value={filters[key] || ""}
                    onChange={(e) => handleFilterChange(key, e.target.value)}
                    label={filterLabels[key]}
                  >
                    <MenuItem value="">Всі</MenuItem>
                    {getOptionsForFilter(key).map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ))}

            <Grid item xs={12} sm={1.5}>
              <MDBInput
                label="Починаючи з дати"
                type="date"
                className="pb-2"
                value={filters.startDate}
                onChange={(e) =>
                  handleFilterChange("startDate", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={1.5}>
              <MDBInput
                label="Включно до дати"
                type="date"
                className="pb-2"
                value={filters.endDate}
                onChange={(e) => handleFilterChange("endDate", e.target.value)}
              />
            </Grid>
          </Grid>
        </Collapse>
      </Box>
    </div>
  );
};

export default InfoCardFilters;
