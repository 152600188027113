import Logout from "@mui/icons-material/Logout";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import UserService from "../../api/user/UserService";

const AccountMenu = ({ setShowBasic }) => {
  const { userLogout, isMobile, user, isLoggedIn } = useAuth();
  const [open, setOpen] = React.useState(false);
  const [menuPosition, setMenuPosition] = React.useState(null);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const navigate = useNavigate();

  const fetchUserInfo = async () => {
    try {
      const userInfo = await UserService.getUserInfo();
      if (userInfo.avatar) {
        const binaryData = atob(userInfo.avatar);
        const bytes = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
          bytes[i] = binaryData.charCodeAt(i);
        }
        const blob = new Blob([bytes], { type: 'image/jpeg' });
        const avatarUrl = URL.createObjectURL(blob);
        setAvatarUrl(avatarUrl);
      }
    } catch (error) {
      console.error("Failed to load user data.");
    }
  };

  const handleClick = (event) => {
    setMenuPosition({ top: event.clientY, left: event.clientX });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setMenuPosition(null);
    setShowBasic(false);
  };

  const handleLogout = () => {
    userLogout();
    handleClose();
  };

  const handleProfileClick = () => {
    navigate("/user/profile");
    handleClose();
  };

  const handleMyFamilyClick = () => {
    navigate("/user/my-family");
    handleClose();
  };

  const handleAdminPanelClick = () => {
    navigate("/admin");
    handleClose();
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchUserInfo();
    }
  }, [isLoggedIn]);

  return (
    <React.Fragment>
      <Box
        sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        className={isMobile ? "me-3" : "ms-3 me-5"}
      >
        <Tooltip title="Профіль">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }} src={avatarUrl}></Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition}
        slotProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleProfileClick}>
          <ListItemIcon>
            <PersonIcon fontSize="small"/>
          </ListItemIcon>
          Мій профіль
        </MenuItem>
        <MenuItem onClick={handleMyFamilyClick}>
          <ListItemIcon>
            <FamilyRestroomIcon fontSize="small"/>
          </ListItemIcon>
          Моя родина
        </MenuItem>
        {user?.role === 'ADMIN' && (
          <MenuItem onClick={handleAdminPanelClick}>
            <ListItemIcon>
              <AdminPanelSettingsIcon fontSize="small"/>
            </ListItemIcon>
            Адмін панель
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small"/>
          </ListItemIcon>
          Вийти
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default AccountMenu;
