import { CircularProgress, Typography } from "@mui/material";
import { MDBContainer } from "mdb-react-ui-kit";
import React, { useCallback, useEffect, useRef, useState } from "react";
import FileService from "../api/files/FileService";
import InfoCardFilters from "../components/filters/InfoCardFilters";
import useInfoCardFilter from "../components/filters/useInfoCardFilter";
import { useAuth } from "../context/AuthContext";
import SpendingDashboard from "../components/dashboards/SpendingDashboard";
import DoctorVisitsDashboard from "../components/dashboards/DoctorVisitsDashboard";

const initialFilters = {
  organization: "",
  specialization: "",
  doctor: "",
  startDate: "",
  endDate: ""
};

const Medicine = () => {
  const { isLoggedIn, isLoading, isMobile } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [mode, setMode] = useState("");
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(initialFilters);
  const [searchQuery, setSearchQuery] = useState("");
  const [infoCards, setInfoCards] = useState([]);
  const { filteredInfoCards, getUniqueValues } = useInfoCardFilter(infoCards, filters, searchQuery);
  const [error, setError] = useState(null);
  const [storageUsage, setStorageUsage] = useState(null);
  const [storageUsageError, setStorageUsageError] = useState(null);

  const documentTypes = [
    "Не заповнено",
    "Медичний висновок",
    "Лабораторне дослідження",
    "Комплексна діагностика",
    "Лікарняний",
    "Рецепт",
    "Направлення",
    "Майбутня подія",
    "Плановий візит"
  ];

  const infoCardsRef = useRef(infoCards);

  const clearFilters = () => {
    setFilters(initialFilters);
    setSearchQuery("");
  };

  const fetchInfoCards = useCallback(async () => {
    setError(null);
    try {
      const activeUserId = localStorage.getItem("activeUserId");
      const fetchedInfoCards = await FileService.getAllInfoCards(activeUserId);
      if (fetchedInfoCards.length === 0) {
        setError("Інформаційні картки не знайдені.");
      } else {
        setInfoCards(fetchedInfoCards);
      }
    } catch (error) {
      console.error("Помилка при отриманні інформаційних карток:", error);
      setError("Помилка при отриманні інформаційних карток: " + error);
    }
  }, []);

  useEffect(() => {
    infoCardsRef.current = infoCards;
  }, [infoCards]);

  useEffect(() => {
    if (!isLoading && isLoggedIn) {
      setLoading(true)
      fetchInfoCards().then(r => setLoading(false));
    }
  }, [fetchInfoCards, isLoggedIn, isLoading]);

  if (!isLoading && !isLoggedIn) {
    return (
      <MDBContainer className="d-flex justify-content-center align-items-center text-center mt-5">
        <Typography
          variant="h4"
          className="d-flex justify-content-center align-items-center text-center mt-5"
        >
          Для доступу необхідно виконати авторизацію
        </Typography>
      </MDBContainer>
    );
  }

  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <CircularProgress/>
        </div>
      )}
      <MDBContainer fluid className="mt-3">
        <InfoCardFilters
          filters={filters}
          setFilters={setFilters}
          getUniqueValues={getUniqueValues}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          clearFilters={clearFilters}
          setIsOpen={setShowModal}
          mode="analytics"
          setMode={setMode}
          storageUsage={storageUsage}
          storageUsageError={storageUsageError}
          documentTypes={documentTypes}
        />
        {error ? (
          <div
            className="container alert alert-warning text-center mt-3"
            role="alert"
          >
            {error}
          </div>
        ) : (
          <div>
            <SpendingDashboard filteredInfoCards={filteredInfoCards}></SpendingDashboard>
            <DoctorVisitsDashboard filteredInfoCards={filteredInfoCards}></DoctorVisitsDashboard>
          </div>
        )}
      </MDBContainer>
    </>
  );
};

export default Medicine;
