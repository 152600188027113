import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { authEvents } from "./AuthEventEmitter";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [redirectPath, setRedirectPath] = useState("/");
  const [isMobile, setIsMobile] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const userAgent =
      typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );
    setIsMobile(mobile);
  }, []);

  useEffect(() => {
    const handleLogout = () => {
      userLogout();
    };
    authEvents.on("logout", handleLogout);
    return () => {
      authEvents.off("logout", handleLogout);
    };
  }, []);

  useEffect(() => {
    const checkAuthStatus = () => {
      const token = localStorage.getItem("accessToken");
      if (token && !isTokenExpired(token)) {
        const decodedToken = parseJwt(token);
        setIsLoggedIn(true);
        setUser(decodedToken);
        localStorage.setItem("userId", decodedToken.sub);
        if (decodedToken.familyId) {
          localStorage.setItem("familyId", decodedToken.familyId);
        }
      } else {
        setIsLoggedIn(false);
        setUser(null);
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userId");
        localStorage.removeItem("familyId");
        localStorage.removeItem("activeUserId");
      }
      setIsLoading(false);
    };

    checkAuthStatus();
  }, []);

  const parseJwt = (token) => {
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error("Invalid token:", error);
      return null;
    }
  };

  const isTokenExpired = (token) => {
    const decodedToken = parseJwt(token);
    if (!decodedToken || !decodedToken.exp) {
      return true;
    }

    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
  };

  const userLogin = (accessToken) => {
    localStorage.setItem("accessToken", accessToken);
    const decodedToken = parseJwt(accessToken);
    localStorage.setItem("userId", decodedToken.sub);
    if (decodedToken.familyId) {
      localStorage.setItem("familyId", decodedToken.familyId);
    }
    setIsLoggedIn(true);
    setUser(decodedToken);
    navigate(redirectPath);
  };

  const userLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("familyId");
    localStorage.removeItem("activeUserId");
    setIsLoggedIn(false);
    setUser(null);
    navigate("/");
  };

  useEffect(() => {
    if (!isLoggedIn && !isLoading) {
      setRedirectPath(location.pathname);
    }
  }, [isLoggedIn, isLoading, location.pathname]);

  const value = {
    isMobile,
    isLoggedIn,
    isLoading,
    user,
    userLogin,
    userLogout
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
