import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import Fab from '@mui/material/Fab';

function CircularProgressWithLabel(props) {
  const [showCheckIcon, setShowCheckIcon] = useState(false);

  useEffect(() => {
    if (props.value === 100) {
      const timer = setTimeout(() => {
        setShowCheckIcon(true);
      }, 500);
      return () => clearTimeout(timer);
    } else {
      setShowCheckIcon(false);
    }
  }, [props.value]);

  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      {showCheckIcon ? (
        <Fab
          aria-label="save"
          color="primary"
          sx={{ bgcolor: 'primary', width: props.size, height: props.size }}
        >
          <CheckIcon style={{ color: 'white', fontSize: props.size * 0.6 }}/>
        </Fab>
      ) : (
        <CircularProgress variant="determinate" {...props}
                          sx={{ width: props.size, height: props.size, color: 'primary' }}/>
      )}
      <Box
        sx={{
          top: '50%',
          left: '50%',
          bottom: '50%',
          right: '50%',
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Typography variant="caption" component="div" color="white">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export default CircularProgressWithLabel;
