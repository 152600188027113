import axiosClient from "../config/AxiosService";
import EncryptionService from "../encryption/EncryptionService";

const UserService = {
  async getUserInfo() {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosClient.get(`/user/me`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      return response.data;
    } catch (error) {
      console.error("Failed to fetch storage usage:", error);
      throw error;
    }
  },

  async getAllUsers() {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosClient.get(`/user/all`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      return response.data;
    } catch (error) {
      console.error("Failed to fetch storage usage:", error);
      throw error;
    }
  },

  async getSubscriptions() {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosClient.get(`/user/subscription/get-all`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      return response.data;
    } catch (error) {
      console.error("Failed to get subscriptions:", error);
      throw error;
    }
  },

  async changePassword(currentPassword, newPassword) {
    try {
      const encryptedCurrentPassword = EncryptionService.encrypt(currentPassword);
      const encryptedNewPassword = EncryptionService.encrypt(newPassword);
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosClient.post(`/user/password/change`, {
          currentPassword: encryptedCurrentPassword,
          newPassword: encryptedNewPassword
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
      return response.data;
    } catch (error) {
      console.error("Failed to update password:", error);
      throw error;
    }
  },

  async deleteAccount() {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosClient.get(`/user/delete`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      return response.data;
    } catch (error) {
      console.error("Failed to fetch storage usage:", error);
      throw error;
    }
  },

  async updateUserSubscription(subscriptionId) {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosClient.post(`/user/subscription/update`, {
          subscriptionId: subscriptionId
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
      return response.data;
    } catch (error) {
      console.error("Failed to update subscription:", error);
      throw error;
    }
  },

  async updateUserName(newName) {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosClient.post(`/user/name/change`, {
          newName: newName
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
      return response.data;
    } catch (error) {
      console.error("Failed to update subscription:", error);
      throw error;
    }
  },

  async uploadAvatar(avatarFile) {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const formData = new FormData();
      formData.append("file", avatarFile);
      const response = await axiosClient.post(`/user/avatar/upload`, formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
      return response.data;
    } catch (error) {
      console.error("Failed to upload avatar:", error);
      throw error;
    }
  }
};

export default UserService;
