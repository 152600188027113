import axiosClient from "../config/AxiosService";
import EncryptionService from "../encryption/EncryptionService";

const AuthService = {
  async authenticate(email, password) {
    const encryptedEmail = EncryptionService.encrypt(email);
    const encryptedPassword = EncryptionService.encrypt(password);
    const response = await axiosClient.post("/auth/login", {
      email: encryptedEmail,
      password: encryptedPassword
    });
    if (response.data) {
      localStorage.setItem("accessToken", response.data);
    }
    return response;
  },

  async signup(user) {
    const encryptedEmail = EncryptionService.encrypt(user.email);
    const encryptedPassword = EncryptionService.encrypt(user.password);
    const encryptedAuthCode = EncryptionService.encrypt(user.authCode);
    const encryptedName = EncryptionService.encrypt(user.name);
    const payload = {
      email: encryptedEmail,
      password: encryptedPassword,
      authCode: encryptedAuthCode,
      name: encryptedName
    };
    return await axiosClient.post("/auth/signup", payload);
  }
};

export default AuthService;
