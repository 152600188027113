import React from "react";
import { MDBContainer } from "mdb-react-ui-kit";
import { Typography } from "@mui/material";
import { useAuth } from "../context/AuthContext";

const Homepage = () => {
  const { isLoggedIn } = useAuth();

  return (
    <MDBContainer className="d-flex justify-content-center align-items-center text-center mt-5">
      <Typography
        variant="h4"
        className="d-flex justify-content-center align-items-center text-center mt-5"
      >
        {isLoggedIn ? "Ви авторизовані, все добре!" : "Для доступу необхідно виконати авторизацію"}
      </Typography>
    </MDBContainer>
  );
};

export default Homepage;
