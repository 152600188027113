import { useMemo } from "react";

const useInfoCardFilter = (infoCards, filters, searchQuery) => {

  const filteredInfoCards = useMemo(() => {
    return infoCards.filter(
      (infoCard) =>
        (!filters.documentType ||
          infoCard.documentType === filters.documentType ||
          (infoCard.documentType === "" && filters.documentType === "5")) &&
        (!filters.organization ||
          infoCard.organization === filters.organization) &&
        (!filters.specialization ||
          infoCard.specialization === filters.specialization) &&
        (!filters.doctor || infoCard.doctor === filters.doctor) &&
        (!filters.startDate ||
          new Date(infoCard.date) >= new Date(filters.startDate)) &&
        (!filters.endDate ||
          new Date(infoCard.date) <= new Date(filters.endDate)) &&
        (!searchQuery ||
          infoCard.cardName.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  }, [infoCards, filters, searchQuery]);

  const getUniqueValues = (key, currentFiltering) => {
    const relevantInfoCards = infoCards.filter((infoCard) => {
      return Object.keys(currentFiltering)
        .filter((fk) => fk !== key && currentFiltering[fk])
        .every((filterKey) => {
          if (filterKey === "startDate" || filterKey === "endDate") {
            const cardDate = new Date(infoCard.date);
            const startDate = new Date(currentFiltering.startDate);
            const endDate = new Date(currentFiltering.endDate);
            return filterKey === "startDate"
              ? cardDate >= startDate
              : cardDate <= endDate;
          }
          return infoCard[filterKey] === currentFiltering[filterKey];
        });
    });
    return [
      ...new Set(relevantInfoCards.map((infoCard) => infoCard[key]))
    ].filter((item) => item);
  };

  return { filteredInfoCards, getUniqueValues };
};

export default useInfoCardFilter;
